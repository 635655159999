// ------------------------------------------------------------------- //
//  Search
// ------------------------------------------------------------------- //

// No search or no results
.search-results--none {
  margin-bottom: 0;
  padding: 35px 0;
  color: #999;
  font-size: $base-font-size;
  font-style: italic;
}

.search-page__results {
  label[for="search-sort-by"] {
    display: inline-block;
    margin: 0 15px 0 0;
  }

  form {
    display: inline-block;
    width: 200px;
  }

  .chosen-container {
    margin-bottom: 0;
  }

  // Media object tweaks
  .media__datetime {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .media__product {
    margin-top: 10px;
  }
}

.search-page__sidebar {
  .content-block__header {
    border-bottom-color: $brand-color-3c;
  }

  .content-block__title {
    color: $brand-color-3c;
  }
}

.search-filter {
  display: inline-block;
  margin: 0 5px 5px 0;
  padding: 5px 10px;
  background: #f0f0f0;
  font-size: $mini-size;
  line-height: 14px;
  transition: background .3s;

  &:first-of-type {
    margin-top: 15px;
  }

  &:hover {
    background: darken(#f0f0f0, 5%);
  }

  &:after {
    float: right;
    color: #666;

    &:hover {
      color: #333;
    }
  }
} // END search-filter

// No results
.search-filter--none {
  margin-bottom: 0;
  padding-top: 15px;
  color: #999;
  font-size: $mini-size;
  font-style: italic;
}

.search-filter-actions {
  @include clearfix;
  margin-bottom: 60px;
}

.search-filter__save,
.search-filter__clear {
  float: left;
  width: 125px;
  margin-top: 15px;
  text-align: center;
}

.search-filter__save {
  margin-right: 5px;
}

label[for="search-terms-form"] {
  margin: 15px 0 0;
  text-transform: uppercase;
}

.search-terms-form {
  margin-bottom: 30px;
  padding: 10px;
  border: 1px solid $base-border-color;

  .search-terms-form__input {
    float: left;
    width: 204px;
  }

  .form-submit {
    float: right;
    display: block;
    width: 30px;
    height: 30px;
    background: url("/sites/all/themes/ingenia/images/ta-submit-gray.png") no-repeat 0 0;
    border: 0;
    text-indent: -9999px;
    transition: none;

    &:hover {
      background-position: 0 -30px;
    }
  }

  input[type="text"] {
    margin-bottom: 5px;
    background: $brand-color-3e;
    border-top: 2px solid $brand-color-3d;
  }

  .form-type-select {
    margin-bottom: 0;
  }

  .chosen-container {
    width: 100% !important;
    margin-bottom: 0;
  }
} // END search-terms-form

label[for="search-restrict-subscribed"] {
  margin-bottom: 30px;
  font-weight: $normal;
  text-transform: none;
  cursor: pointer;

  .form-type-checkbox {
    display: inline-block;
  }
}

.search-facets-content {
  margin-top: 10px;
}

.search-filter-options {
  .input-group {
    height: 100%;
  }

  .input-group__action {
    height: 100%;
    vertical-align: top;
  }

  .chosen-container {
    margin-bottom: 0;
  }

  .or-toggle {
    width: 100%;
    height: 100%;
    padding: 0;
    background: #ccc;
    border: 0;
    line-height: 2;
    vertical-align: top;

    &.or-selected {
      background: #999;
      border: 0; // Override red border, from ?
      opacity: 1;
    }
  }

  label {
    margin-top: 15px;
  }
} // END search-filter-options



// -------------------------------------------------------------------- //
//  Search by Date
// -------------------------------------------------------------------- //

.search-date-range {
  margin-bottom: 30px;
  
  .radios-content {
    margin-bottom: 10px;
  }

  .date-padding {
    float: none;
    padding: 0;
  }

  .label-init-content,
  .label-end-content {
    margin-top: 10px;
  }

  .container-inline-date {
    .form-item,
    .form-text {
      margin-bottom: 0;
    }
  }

  .granular-date-submit {
    margin-top: 10px;
  }
}

.search-date-range__helper {
  margin-bottom: 0;
  padding: 5px 0;
  color: #999;
  font-size: $mini-size;
  font-style: italic;
}


// -------------------------------------------------------------------- //
//  Quick Search
// -------------------------------------------------------------------- //

.quick-search-submit {
  display: block;
  @include size(30px);
  background: url("../images/ta-submit-gray.png") 0 0 no-repeat;
  border: 0;
  text-indent: -9999px;

  &:hover {
    background-position: 0 -30px;
  }
}


// -------------------------------------------------------------------- //
//  Search Help
// -------------------------------------------------------------------- //

.search-help__content {
  padding-top: 10px;
  line-height: 1.2;

  p {
    margin-bottom: 5px;
  }

  small {
    font-weight: 800;
  }
}

.search-help__title {
  margin-bottom: 5px;
  font-weight: 800;

  + p {
    margin: -5px 0 20px;
  }
}

.search-connector,
.code-section-searches,
.retrieving-documents-citation,
.field-restricted-searching {
  clear: both;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.field-restricted-searching {
  border-bottom: 0;
}

.search-connector__operator {
  float: left;
  width: 15%;
  font-weight: 800;
}

.search-connector__description {
  display: block;
  overflow: hidden;
}

.search-example {
  width: 100%;
  margin-bottom: 0;

  small {
    float: right;
  }
}

.available-fields {
  @include clearfix;
  margin: 20px 0 10px;
}

.available-fields__options {
  float: left;
  width: 33%;
  margin-bottom: 5px;
  list-style: none;
}

.search-help__close {
  display: block;
  margin: 10px 0;
  font-family: $base-font-family;
  font-style: italic;
  text-align: center;
  cursor: pointer;

  &:after {
    color: $brand-color-2;
  }
}

// On Adv. Search page
#adv-search-connector-help {
  display: none;
  max-width: 650px;
  max-height: 80vh;
  background: #fff;
  padding: 15px;
  font-size: 12px;
  box-shadow: 0 0 15px 0 #333;
  overflow-y: scroll;

  .search-help__content {
    padding-top: 0;
  }
}
