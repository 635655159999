// ------------------------------------------------------------------- //
//  Header
// ------------------------------------------------------------------- //

.header {
  position: relative;
  z-index: 10;
  margin-bottom: 30px;
  font-family: $brand-font-family;

  @media screen and (max-width: $tablet){
    position: fixed;
    top: 60px;
    bottom: 0;
    left: -100%;
    width: 100%;
    margin-bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #fff;

    &.active {
      left: 0;
    }
  }

  @include screen($mobile, $tablet) {
    background: rgba(0, 0, 0, 0.8);
  }
} // END header


// ------------------------------------------------------------------- //
//  Nav Counters
// ------------------------------------------------------------------- //

.menu-counter {
  margin-left: 10px;
  padding: 3px 5px;
  background: $brand-color--light;
  border-radius: 2px;
  text-align: center;
}


// ------------------------------------------------------------------- //
//  Nav Rows
// ------------------------------------------------------------------- //

.ta-logo {
  float: left;
  display: block;
  @include size(175px 70px);
  background-image: url("../images/taxnotes-logo.png");
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
  text-indent: -1000px; // IE8 hack
  overflow: hidden;

  &:hover {
    color: transparent;
  }

  @media screen and (max-width: $tablet){
    display: none;
  }
} // END ta-logo


// ------------------------------------------------------------------- //
//  Search Module
// ------------------------------------------------------------------- //

.search-module {
  display: none;
  padding: 10px 0;
  background: #efefef;
  font-family: $brand-font-family;
  box-shadow: inset 0 15px 15px -15px rgba(black, .2),
              inset 0 -15px 15px -15px rgba(black, .2);
  overflow: hidden;

  label {
    float: left;
    display: block;
    width: 225px;
    margin-top: 14px;
    margin-right: 10px;
    color: $brand-color-3b;
    font-size: 22px;
    font-weight: $light;
    text-align: right;
    text-transform: none;
    vertical-align: middle;
  } // END label

  .input-group {
    float: left;
    max-width: 515px;
    margin-top: 17px;
    padding-left: 4px;

    .form-item {
      @extend .input-group__form;

      input {
        background: #fff;
      }
    } // END form-item

    .search-module__submit {
      display: block;
      @include size(30px);
      background: url("../images/ta-submit.png") no-repeat 0 0;
      border: 0;
      text-indent: -9999px;

      &:hover {
        background-position: 0 -30px;
      }
    } // END search-module__submit
  } // END input-group

  .search-module__adv {
    display: inline-block;
    height: 40px;
    margin: 12px 0 0 25px;
    padding-right: 30px;
    border-right: 1px solid $base-border-color;
    color: $brand-color-3c;
    font-size: 14px;
    cursor: pointer;

    &:before {
      margin: 11px 10px 0 0;
    }

    &:hover {
      color: $brand-color-2;
    }
  } // END icon-equalizer

  .search-module__close {
    float: right;
    margin: 20px 24px 0 0;
    color: $brand-color-3c;
    cursor: pointer;

    &:before {
      margin: 3px 6px 0 0;
      font-size: 22px;
    }

    &:hover {
      color: $brand-color-3b;
    }
  } // END icon-cancel
} // END search-module

.search-help__prompt {
  margin-bottom: 0;
  margin-left: 240px;
  font-family: $base-font-family;
  font-style: italic;
  cursor: pointer;

  &:after {
    color: $brand-color-2;
  }

  .search-help__content {
    display: none;
    width: 650px;
    margin-left: 240px;
  }
}






// -------------------------------------------------------------------- //
//  Mobile
// -------------------------------------------------------------------- //

.header--mobile {
  display: none;
  width: 100%;
  height: 60px;
  margin-bottom: 30px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.1);

  img {
    display: block;
    margin: 0 auto 15px;
  }

  &.fix {
    z-index: 10;
    position: fixed;
    top: 0;
  }

  @media screen and (max-width: $tablet){
    display: block;
    padding: 15px 0 0;
    background: #fff;

    .front & {
      margin-bottom: 0;
    }
  }
} // END nav--mobile

.header--mobile__menu {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: $brand-color-3c;
  text-align: center;

  &:before {
    margin: 0;
    font-size: 26px;
  }
}

// .header--mobile__today {
//   display: none;
//   height: 40px;
//   padding: 10px 0;
//   background: $brand-color;
//   color: #fff;
//   text-transform: uppercase;

//   @media screen and (max-width: $mobile) {
//     display: block;
//   }
// }

@media screen and (max-width: $tablet){
  .region-page-top{
    display: none;
  }
}
