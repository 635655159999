// ------------------------------------------------------------------- //
//  Info Bars
// ------------------------------------------------------------------- //

%info-bar {
  display: table;
  width: 100%;
  height: 35px;
  margin-bottom: 20px;
  background: $brand-color-3e;
  @include border(0 0 2px 0, solid, $base-border-color);
  color: $brand-color-3b;
  font-size: $milli-size;
  line-height: 1.1;
  text-transform: uppercase;
  vertical-align: middle;

  .ie8 & {
    min-height: auto;
  }
} // END %info-bar


.top-news-date {
  @extend %info-bar;
  padding: 10px 0;
  text-align: center;

  @media screen and (max-width: $mobile) {
    display: block;
    width: auto;
    margin: 0 -20px 35px;
  }
} // END top-news-date


%info-bar--nav {
  @extend %info-bar;

  a {
    color: $brand-color-3b;
  }

  p {
    margin-bottom: 0;
  }

  [class*="__btn"] {
    display: inline-block;
    width: 35px;
    padding: 10px 0;
    background: $brand-color-3c;
    color: $brand-color-3e;
    text-align: center;
    transition: all .3s;

    &:before {
      margin: 0;
    }

    @media screen and (max-width: $mobile) {
      padding: 20px 0;
    }
  }

  [class*="__label"] {
    display: inline-block;
    padding: 10px 0;
    color: $brand-color-3c;
    font-weight: $bold;
    transition: all .3s;

    @media screen and (max-width: $mobile) {
      display: none;
    }
  }

  [class*="--prev"],
  [class*="--detail"],
  [class*="--next"] {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
  }

  [class*="--prev"],
  [class*="--next"] {
    width: 20%;
    cursor: pointer;

    &:hover {
      [class*="__label"] {
        color: $brand-color-3b;
      }

      [class*="__btn"] {
        background: $brand-color-3b;
      }
    }

    &:empty {
      cursor: default;
    }

    @media screen and (max-width: $mobile) {
      width: auto;
    }

    @include screen($mobile, $tablet) {
      width: 25%;
    }
  }

  [class*="--prev"] {
    [class*="__btn"] {
      margin-right: 10px;

      @media screen and (max-width: $mobile) {
        margin: 0;
      }
    }
  }

  [class*="--next"] {
    text-align: right;

    [class*="__btn"] {
      margin-left: 10px;

      @media screen and (max-width: $mobile) {
        margin: 0;
      }
    }
  }

  [class*="--detail"] {
    padding: 0 15px;
    text-align: center;
  }
} // END info-bar--nav
