// ------------------------------------------------------------------- //
//  Magazine Landing
// ------------------------------------------------------------------- //
//  Magazine Colors

$tn-color:   #004078;
$stn-color:  #006838;
$eotr-color: #004078;
$itr-color:  #004078;
$tni-color:  #952226;


.magazine {
  // Set Colors
  &.tn .magazine--landing__content,
  &.tn .magazine--article__content {
    .magazine__title { background: $tn-color; }
    .content-block__header { border-bottom-color: $tn-color; }
    .content-block__title { color: $tn-color; }
    .magazine__otc .media { border-bottom-color: tint($tn-color, 80%);}

    hgroup .article__title { color: $tn-color; }

    footer .header-title { color: $tn-color; }
  }

  &.stn .magazine--landing__content,
  &.stn .magazine--article__content {
    .magazine__title { background: $stn-color; }
    .content-block__header { border-bottom-color: $stn-color; }
    .content-block__title { color: $stn-color; }
    .magazine__otc .media { border-bottom-color: tint($stn-color, 80%);}

    hgroup .article__title { color: $stn-color; }

    footer .header-title { color: $stn-color; }
  }

  &.eotr .magazine--landing__content,
  &.eotr .magazine--article__content {
    .magazine__title { background: $eotr-color; }
    .content-block__header { border-bottom-color: $eotr-color; }
    .content-block__title { color: $eotr-color; }
    .magazine__otc .media { border-bottom-color: tint($eotr-color, 80%);}

    hgroup .article__title { color: $eotr-color; }

    footer .header-title { color: $eotr-color; }
  }

  &.itr .magazine--landing__content,
  &.itr .magazine--article__content {
    .magazine__title { background: $itr-color; }
    .content-block__header { border-bottom-color: $itr-color; }
    .content-block__title { color: $itr-color; }
    .magazine__otc .media { border-bottom-color: tint($itr-color, 80%);}

    hgroup .article__title { color: $itr-color; }

    footer .header-title { color: $itr-color; }
  }

  &.tni .magazine--landing__content,
  &.tni .magazine--article__content {
    .magazine__title { background: $tni-color; }
    .content-block__header { border-bottom-color: $tni-color; }
    .content-block__title { color: $tni-color; }
    .magazine__otc .media { border-bottom-color: tint($tni-color, 80%);}

    hgroup .article__title { color: $tni-color; }

    footer .header-title { color: $tni-color; }
  }

  // Pagination
  .listing__pagination--prev,
  .listing__pagination--next {
    width: 25%;
  }
} // END magazine

.magazine__title {
  padding: 20px 20px 15px;

  h1 {
    margin-bottom: 0;
    color: #fff;
    font-family: "Impact";
    font-size: 32px;
    font-weight: $normal;
    line-height: 1;
    text-transform: lowercase;

    @media screen and (max-width: $mobile) {
      text-indent: -9999px;
      overflow: hidden;  
    
      .tn & {
        width: 126px;
        height: 27px;
        background-image: url("../images/ta-mag-tn-logo.jpg");
      } 

      .stn & {
        width: 126px;
        height: 59px;
        background-image: url("../images/ta-mag-stn-logo.jpg");
      }

      .tni & {
        width: 178px;
        height: 65px;
        background-image: url("../images/ta-mag-tni-logo.jpg");
      }
    }
  }
} // END magazine__title

// On the Cover
.magazine__otc {
  padding: 20px 20px 0;
  @include border(0 1px, solid, $base-border-color);

  .otc--featured {
    @include clearfix;
    border-bottom: 0;
  }

  .media__image {
    width: auto;
    max-width: 275px;
    height: auto;
    padding-top: 20px;

    @media screen and (max-width: $mobile) {
      width: 100%;
      max-width: 100%;
      text-align: center;

      + .media__content {
        clear: both;
        padding: 0;
      }
    }
  }

  .otc--list {
    clear: both;

    .media {
      @include border(1px 0 0, solid, $base-border-color);
    }

    .media__content {
      padding: 10px 0;
    }

    .media__title {
      margin-top: 0;
      font-size: 18px;
      font-weight: $bold;
    }
  }
} // END magazine__otc

.magazine__callout {
  margin-bottom: 20px;
  @include border(0 1px, solid, $base-border-color);

  .media {
    width: 50%;
    padding: 20px 20px 0;
    border-bottom: none;
  }
} // END magazine__callout

.magazine__full-list {
  @include clearfix;
  margin-bottom: 60px;
  background: #F5F5F5;
  @include border(0 1px 1px, solid, $base-border-color);

  > .left,
  > .right {
    float: left;
    width: 50%;

    @media screen and (max-width: $mobile) {
      float: none;
      width: 100%;
    }
  }

  .magazine__full-list__category {
    padding: 20px 20px 0;
  }

  .media__title {
    font-size: 18px;
    font-weight: $bold;
  }

  .media__snippet {
    font-size: $milli-size;
  }
} // END magazin__full-lsit


// ------------------------------------------------------------------- //
//  Magazine Sidebar
// ------------------------------------------------------------------- //

.back-to-magazine {
  @extend %vertical-rhythm;
  display: block;
  padding: 5px 0;
  border-bottom: 1px solid $base-border-color;
  color: $brand-color-3c;
  font-family: $brand-font-family;
  font-size: $micro-size;
  font-weight: $bold;
  text-transform: uppercase;
}

.magazine__quick-search {
  margin-top: 15px;
} // END magazine__quick-search

.magazine__current-issue-pdf {
  margin: 15px 0 5px;
  font-size: $milli-size;

  + .btn--tertiary {
    padding: 7px 10px;

    &:after {
      display: none;
    }

    @media screen and (max-width: $mobile) {
      padding: 12px 10px;
    }
  }
} // END magazine__current-issue-pdf

.past-issues {
  position: relative;
  border-bottom: 1px solid $base-border-color;

  .chosen-container {
    width: 100% !important;
    margin-bottom: 0;
    padding: 10px;
    border-bottom: 1px solid $base-border-color;
  }

  .media__content {
    padding: 10px;
  }

  .media__datetime {
    margin-bottom: 0;
  }

  .issues-wrapper {
    max-height: 350px;
    overflow-y: scroll;
  }
} // END magazine__past-issues__year

.magazine__toc {
  font-family: $brand-font-family;
  list-style: none;

  .toc__link {
    display: block;
    width: 100%;
    padding: 5px 10px;
    border-bottom: 1px solid $base-border-color;
    color: $brand-color-3b;
    font-size: $micro-size;
    font-weight: $bold;
    text-transform: uppercase;
    transition: all .3s;

    &:hover,
    &.current {
      background: $brand-color-3e;
      color: $brand-color-2;
    }
  }
} // END magazine__toc

.view-issues-calendar {
  display: block;
  padding: 10px 20px;
  font-weight: $bold;
} // END view-issues-calendar



// ------------------------------------------------------------------- //
//  Magazine Home Page Block
// ------------------------------------------------------------------- //

.home__magazines-overview {
  @include clearfix;
  margin-bottom: 60px;

  @media screen and (max-width: $mobile) {
    .media__content {
      padding: 10px 0;
    }
  }
}

.magazines-overview__tabs-nav {
  @include clearfix;
  margin-bottom: 20px;
}

.magazines-overview__tab {
  float: left;
  display: block;
  margin-right: 15px;
  padding: 0 10px;
  border-bottom: 2px solid;
  font-family: $brand-font-family;
  font-size: $tiny-size;
  font-weight: $black;
  line-height: 30px;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }

  &.tax-notes { 
    border-bottom-color: $tn-color;
    color: $tn-color;

    &.active {
      background: $tn-color;
      color: #fff;
    }
  }

  &.state-tax-notes { 
    border-bottom-color: $stn-color;
    color: $stn-color;

    &.active {
      background: $stn-color;
      color: #fff;
    }
  }

  &.tax-notes-international { 
    border-bottom-color: $tni-color;
    color: $tni-color;

    &.active {
      background: $tni-color;
      color: #fff;
    }
  }

  @media screen and (max-width: $tablet) {
    float: none;
    width: 100%;
  }

  @include screen($mobile, $tablet) {
    padding: 5px 10px;
  }
} // END magazines-overview__tab

.magazines-overview__tab-content {
  .magazines-overview__issue {
    overflow: hidden;
    margin-bottom: 0;
    font-family: $brand-font-family;
    font-size: $micro-size;
    font-weight: $bold;
  }

  .magazine__otc {
    padding: 0;
    border: 0;
  }

  .media__title {
    font-weight: 400;
  }

  .media__image {
    padding-top: 0;
    padding-right: 20px;

    @media screen and (max-width: $tablet) {
      float: none;
      max-width: 100%;
      padding-right: 0;
    }
  }

  .view-full-magazine {
    float: right;
    margin-top: 15px;

    &.tax-notes { 
      background-color: $tn-color;
      border-bottom-color: $tn-color;
    }

    &.state-tax-notes { 
      background-color: $stn-color;
      border-bottom-color: $stn-color;
    }

    &.tax-notes-international { 
      background-color: $tni-color;
      border-bottom-color: $tni-color;
     }
  }
}
