// ------------------------------------------------------------------- //
//  Media Object
// ------------------------------------------------------------------- //

.media {
  position: relative;
  width: auto;
  border-bottom: 1px solid $brand-color-3e;

  &:last-of-type {
    border-bottom: 0;
  }

  &.blogger-info {
    margin: 20px 0 0;
    border: 0;
  }

  // Hide loading gif for RQ add/remove
  .flag-throbber {
    display: none;
  }

  // Reposition RQ button
  .flag-wrapper {
    @include position(absolute, 10px 0px 0 0);
  }

  .add-to-queue,
  .remove-from-queue {
    display: block;
    @include size(40px);
    padding: 10px;
    text-align: center;
    transition: color .3s;

    &:before {
      font-size: 16px;
      margin: 0;
    }
  }

  .add-to-queue {
    color: #666;

    &:hover {
      color: $brand-color-2;
    }
  }

  .remove-from-queue {
    color: $brand-color-2;

    &:hover {
      color: $brand-color-2--dark;
    }
  }
} // END media

// Media images
.media__image {
  float: left;
  @include size(100px);

  + .media__content {
    padding: 20px;
  }
}

// Media Content
.media__content {
  overflow: hidden;
  padding: 20px 0px;
}

// Media Datetime/Author
.media__datetime,
.media__author,
.media__product {
  color: $brand-color-3c;
  font-family: $brand-font-family;
  font-size: $micro-size;
  font-weight: $black;
  line-height: 1;
  text-transform: uppercase;

  a {
    color: $brand-color-3c;

    &:hover {
      color: $brand-color-3a;
    }
  }
} // END datetime/author

// Media Datetime
.media__datetime {
  margin-bottom: 10px;
  letter-spacing: 1pt;
}

// Media Product Name
.media__product {
  margin-bottom: 10px;
  letter-spacing: 0;
}

// Media title
.media__title {
  margin-bottom: 0;
  color: $brand-color-3a;
  font-size: 24px;
  font-weight: $normal;
  line-height: 1;

  a {
    color: $brand-color-3a;

    &:hover {
      color: #000;
    }

    &:visited {
      color: $brand-color-3c;
    }
  }
} // END title

// Media Author
.media__author {
  margin-top: 10px;
  margin-bottom: 0;
}

// Media snippet
.media__snippet {
  margin-top: 20px;
  margin-bottom: 0;
  color: $brand-color-3b;
  line-height: 1.4;

  &:empty,
  p {
    margin: 0;
  }
}

// Daily Tags
.media__sub-category {
  display: inline-block;
  margin: 10px 0 5px;
  padding: 3px 5px;
  background: $brand-color-3e;
  font-size: $milli-size;

  & + .media__meta {
    padding-top: 0;
  } // END media__tag + .media__meta
} // END media__tag
