// ------------------------------------------------------------------- //
//  Breadcrumbs
//
//  Must be extended in the ul of the breadcrumbs
//  Adds auto-magical separators
// ------------------------------------------------------------------- //

// Simple breadcrumb ">"
%breadcrumb {
  & > a + a:before {
    content: "";
    display: inline-block;
    @include size(4px 8px);
    background: url('../images/breadcrumb-arrow.png') no-repeat center center;
    margin: 0 5px;
  }
}

// Path breadcrumb "/"
%breadcrumb--path {
  & > a + a:before {
    content:"/";
  }
}

%breadcrumb__root {
  font-weight: 900;
}
