// ------------------------------------------------------------------- //
//  Show / Hide Elements
//  To be used in jQuery as a toggle class
// ------------------------------------------------------------------- //

.is-shown {
  display: block;
}
.is-hidden {
  display: none;
}