// ------------------------------------------------------------------- //
//  Clearfix
// 
//  Source:
//  css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
// ------------------------------------------------------------------- //

@mixin clearfix {
  display: block;
  clear: both;
  
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
