// ------------------------------------------------------------------- //
//  Nav Extends
// ------------------------------------------------------------------- //

%nav-link {
  font-size: $micro-size;
  font-weight: $black;
  text-transform: uppercase;
}

// ------------------------------------------------------------------- //
//  Mega Menu Styles
// ------------------------------------------------------------------- //

%mega-menu {
  z-index: 5;
  @include position(fixed, 0px 0 0 0px);
  width: 100%;
  height: 100%;
  background: #000; // IE fallback
  background: rgba(#000, .93);
  font-size: $milli-size;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
  overflow: scroll;

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  .mega-menu__header {
    @include outer-container;
    position: relative;

    @media screen and (max-width: $mobile) {
      padding: 0 15px;
    }
  }

  .mega-menu__title {
    margin: 20px 0 10px;
    color: $brand-color-3d;
    text-align: center;
  }

  .mega-menu__input-filter {
    margin-bottom: 20px;
    background: #777;
    border: 1px solid #000;
    color: #fff;
    text-align: center;

    &:hover,
    &:focus,
    &:active {
      border: 1px solid #000;
    }

    @media screen and (max-width: $mobile) {
      height: 40px;
    }
  }

  .mega-menu__close {
    @include position(absolute, 15px 0px 0 0);
    color: #fff; // IE fallback
    color: rgba(#fff, .8);
    text-transform: uppercase;
    cursor: pointer;

    .icon-cancel-circled:before {
      margin: -3px 0 0 5px;
      color: #fff;
      font-size: 22px;
      vertical-align: middle;
    }

    &:hover {
      color: rgba(#fff, 1);
    }

    @media screen and (max-width: $mobile) {
      top: -5px;
      right: 15px;
      font-size: 0;
    }
  }

  .mega-menu__clear {
    @include position(absolute, 60px 0px 0 0);
    font-size: 14px;
    cursor: pointer;

    @media screen and (max-width: $mobile) {
      top: 47px;
      right: 15px;
    }
  }

  li:not([class*="__category"]) {
    margin: 0 15px 2px 5px;
    background: transparent;
    line-height: 1;
    transition: background, .3s;

    &:hover {
      background: #000;
    }
  } // END tax-topics-menu li

  a {
    display: block;
    width: 100%;
    padding: 2px 5px;
    color: #ddd;
    font-family: $base-font-family;
    font-size: $milli-size;
    line-height: 1.3;
    text-shadow: 1px 1px 0 rgba(#000, .5);

    &:hover {
      color: #fff;
    }
  } // END tax-topics-menu a

  ul {
    list-style: none;
  }
} // END mega-menu

// Top Grey Nav
.nav--top {
  position: relative;
  z-index: 3;
  background: #dedede;
  text-align: right;

  .contain {
    position: relative;
  }

  ul {
    margin-bottom: 0;
    list-style: none;

    @media screen and (max-width: $tablet) {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}

/*li*/.nav-link--top {
  position: relative;
  display: inline-block;
  height: 25px;
  margin: 0 0 0 -3px;

  // Main Links
  > a {
    @extend %nav-link;
    padding: 5px 15px;
    @include border(0 0 0 1px, solid, #e9e9e9);
    color: #888;
    line-height: 25px;
    text-transform: uppercase;
    transition: all .3s;

    @media screen and (max-width: $tablet) {
      display: block;
      padding: 10px 0;
      border: none;
      font-size: 10px;
      line-height: 1;
      opacity: .5;
    }

    @include screen($mobile, $tablet) {
      padding: 15px 0;
    }
  }

  // Main Link (Last Child)
  &:last-child > a {
    border-right: 1px solid #e9e9e9;

    @include max-screen($tablet) {
      border-right: none;;
    }
  }

  // Dropdown
  ul {
    position: absolute;
    top: 25px;
    right: -9999px;
    background: #777;
    text-align: left;
    opacity: 0;

    // Dropdown links
    a {
      display: block;
      margin-bottom: 3px;
      color: #eee;

      &:hover {
        color: #fff;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: $tablet) {
        width: 100%;
      }
    }

    @media screen and (max-width: $tablet) {
      display: none;
      text-align: center;
    }
  } // END nav-link--top submenu

  // li hover
  &:hover {

    // Hover State
    background: #777;

    // Hover State Link Color
    a {
      border-color: transparent;
      color: #fff;
    }

    // Show dropdown
    ul {
      right: 0;
      width: 120px;
      padding: 10px;
      opacity: 1;
    }
  }

  @media screen and (max-width: $tablet){
    flex-basis: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: auto;
    margin: 0;
    border-right: 1px solid #e9e9e9;

    &:last-child {
      border-right-width: 0;
    }
  }
} // END nav-link--top

// Tax Topics
.nav--main {
  position: relative;
  z-index: 2;
  height: 70px;
  background: #efefef;

  .nav--main__nav {
    float: right;

    @media screen and (max-width: $tablet) {
      float: none;
    }
  }

  .icon-search {
    width: 70px;
    height: 70px;
    border-right: 1px solid #e3e3e3;
    font-size: 24px;
    text-align: center;

    &:before {
      margin: 0;
      font-size: $h1-size;
    }

    &.active {
      background: $brand-color;
      color: #fff;
    }

    .ie8 & {
      width: 76px;
    }
  } // END icon-search

  @media screen and (max-width: $tablet){
    height: auto;
  }
} // END nav--main

.nav-link--main {
  float: left;
  @extend %nav-link;
  padding: 20px 25px;
  background: #efefef;
  @include border(0 0 0 1px, solid, #e3e3e3);
  color: #6d6d6d;
  line-height: 30px;
  transition: background-color 0.3s;

  &.active {
    background: #313437; // Blue bar color under 97% #333
    color: #ccc;
  }

  @media screen and (max-width: $tablet){
    float: none;
    display: block;
    width: 100%;
    padding: 5px 20px;
    border-width: 0 0 1px;

    &:last-child{
      display: none;
    }
  }

  @include screen($mobile, $tablet) {
    padding: 10px 20px;
  }
} // END nav-link--main

.tax-topics-menu,
.key-documents-menu,
.jurisdictions-menu,
.hot-topics-menu {
  @extend %mega-menu;

  .mega-menu__category.show {
    width: 25%;
    height: auto;
    padding: 0 10px 0 0;

    @media screen and (max-width: $tablet) {
      width: 100%;
      padding: 0 15px;
      margin-bottom: 10px;
    }
  }
  .mega-menu__category {
    @include size(0);
  }

  .category__title {
    margin: 0 0 5px 0;
    color: $brand-color-2;
    font-family: $brand-font-family;
    font-weight: $black;
    line-height: 1;
    text-transform: uppercase;
    text-shadow: 1px 1px 0 shade($brand-color-2, 65%);
  } // END category__title
} // END tax-topics-menu

// Contributors
.contributors-menu {
  @extend %mega-menu;
  padding-bottom: 0;
} // END contributors-menu

.contributors--featured {
  @include span-columns(4);
  @include hug(both);

  .featured-overview {
    img {
      float: left;
      max-width: 75px;
      height: auto;
      margin: 0 20px 20px 0;
      border: 1px solid white;
    }

    a {
      padding: 0;
    }

    &:hover {
      background: transparent !important; // Override mm default
    }
  } // END featured-overview

  @media screen and (max-width: $tablet) {
    @include span-columns(12);
  }
} // END contributors--featured

.contributor--featured__info {
  font-family: $brand-font-family;
  overflow: hidden;
}

.contributor--featured__label {
  margin-bottom: 10px;
  color: $brand-color-3c;
  font-size: $milli-size;
  font-weight: $bold;
  text-transform: uppercase;
}

.contributor--featured__name {
  color: $brand-color-2;
  font-weight: $normal;
  line-height: 1;
}

.contributor--featured__links {
  clear: both;

  a {
    display: block;
    margin-bottom: 10px;
    padding: 2px 5px;
    color: #ddd;
    font-size: $base-font-size;

    &:hover {
      background: #000;
      color: #fff;
    }
  }
} // END contributor--featured__links

.contributor--featured__view-all {
  font-size: $milli-size;
  font-weight: $bold;
  text-transform: uppercase;
}


.contributors--list {
  @include span-columns(8);

  .column {
    padding-left: 10px;
    border-left: 1px solid #646464;
  }

  li a {
    padding: 5px 0;
    color: $brand-color-2;
    font-size: $base-font-size;
  }

  @media screen and (max-width: $tablet) {
    @include span-columns(12);
  }
} // END contributors--list


// ------------------------------------------------------------------- //
//  Personal Nav
// ------------------------------------------------------------------- //

.personal-welcome,
.user-reading-queue,
.user-favorites,
.publications-menu,
.research-tools-menu {
  position: relative;
  margin: 0;
  list-style: none;
}

.nav--personal {
  position: relative;
  z-index: 1;
  height: 40px;
  background: $brand-color;
  background: linear-gradient(to right, #1a73ab 0%, #1a73ab 50%, $brand-color 50%, $brand-color 100%);
  border-bottom: 3px solid $brand-color--dark;

  .contain {
    height: 40px;
    background: $brand-color;
    border-bottom: 3px solid $brand-color--dark;

    @media screen and (max-width: $tablet) {
      height: auto;
      border: none;
    }
  }

  [class*="icon-"]:before {
    margin-right: .5rem;
    opacity: .5;

    .ie8 & {
      float: left;
      margin-top: 15px;
      line-height: 1;
    }
  } // END nav--personal icon-*

  [class*="--after"]:after {
    opacity: .5;
  }

  .icon-down-dir:after {
    margin: 0 0 0 .5rem;
    opacity: 1;
  }

  @media screen and (max-width: $tablet){
    height: auto;
    border: none;
  }
} // END nav--personal

.nav-link--personal {
  float: left;
  padding: 0 15px;
  border-right: 1px solid darken($brand-color, 3%);
  color: #fff;
  font-size: $milli-size;
  font-weight: 600;
  line-height: 40px;
  text-transform: uppercase;
  transition: background .3s;

  &:hover {
    background: $brand-color--dark;
    color: #fff;
  }

  // Fix grey icon
  &:after {
    color: #fff;
  }

  .ie8 & {
    padding: 0 14px;
    letter-spacing: -0.1em;
  }

  @media screen and (max-width: $tablet){
    float: none;
    display: block;
    padding: 0 20px;
    border-top: 1px solid $brand-color--dark;
  }
} // END nav-link--personal

.nav-link--personal__tools {
  @extend %nav-link;
  display: block;
  height: 40px;
  padding: 0 15px;
  background: $brand-color-2;
  border-bottom: 3px solid $brand-color-2--dark;
  color: #fff;
  line-height: 40px;
  transition: background .3s;

  &:hover {
    background: $brand-color-2--dark;
    color: #fff;
  }

  @media screen and (max-width: $tablet) {
    border-bottom-width: 1px;
  }
} // END nav-link--personal__tools

.queue-count,
.favorite-count {
  margin-left: 10px;
  padding: 3px 5px;
  background: $brand-color--light;
  border-radius: 2px;
  text-align: center;
}

.personal-datetime {
  z-index: 1;
  position: relative;
  height: 40px;
  padding-left: 0px !important; // Remove auto padding from menu item
  background: #1a73ab;
  border-bottom: 3px solid $brand-color--dark;
  color: rgba(white, .75);
  font-weight: $black;

  &:hover {
    background: #1a73ab;
  }

  .ie8 & {
    background: transparent;
  }

  @media screen and (max-width: $tablet) {
    // Initial hide; controlled in jQuery
    display: none;
  }
}

// Personal
.personal-welcome {
  float: left;
  cursor: pointer;

  .nav-link--personal {
    margin: 0 !important; // Override full-menu margin pull
  }

  .personal-welcome__name {
    display: inline-block;
    max-width: 125px;
    line-height: .8;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
  }

  &:hover .nav-link--personal {
    background: $brand-color--dark;
  }

  ul {
    @include position(absolute, 40px 0 0 -9999px);
    min-width: 150px;
    width: 100%;
    background: $brand-color--dark;
    opacity: 0;
    @include transition(opacity, .3s);

    @media screen and (max-width: $tablet) {
      display: none;
      position: relative;
      top: 0;
      left: 0;
      opacity: 1;
      box-shadow: inset 0 3px 10px rgba(0,0,0,0.2),
                  inset 0 -3px 10px rgba(0,0,0,.2);
    }
  }

  // Nav Item
  li {
    list-style: none;

    ul li {
      @include clearfix;
      border-bottom: 1px solid shade($brand-color--dark, 25%);

      a {
        display: block;
        padding: 10px 15px;
        color: rgba(255, 255, 255, .75);
        font-size: $milli-size;
        transition: color .3s, background .3s;

        &:hover {
          background: shade($brand-color--dark, 25%);
          color: rgba(255,255,255,1);
        }
      }
    }
  }

  > li:hover ul {
    left: auto;
    opacity: 1;
  } // END ul li:hover ul

  @media screen and (max-width: $tablet){
    float: none;
    display: block;
  }
} // END personal-welcome

// Reading Queue
.user-reading-queue,
.user-favorites {
  float: left;

  &:hover .nav-link--personal {
    background: $brand-color--dark;
    color: #fff;
  }

  .nav-link--personal {
    margin: 0 !important; // Override full-menu margin pull
  }

  ul {
    @include position(absolute, 40px 0 0 -9999px);
    width: 350px;
    background: $brand-color--dark;
    overflow: auto;
    opacity: 0;
    @include transition(opacity, .3s);

    @media screen and (max-width: $tablet){
      display: none;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 1;
      box-shadow: inset 0 3px 10px rgba(0,0,0,0.2),
                  inset 0 -3px 10px rgba(0,0,0,.2);
    }
  }

  // Nav Item
  li {
    list-style: none;

    // Children
    li {
      display: table;
      width: 100%;
      border-bottom: 1px solid shade($brand-color--dark, 25%);
    }

    .menu-no-results {
      display: block;
      width: 100%;
      padding: 10px;
      color: #fff;
      color: rgba(255,255,255,.75);
      font-size: $milli-size;
      font-style: italic;
      text-align: center;
    }
  }

  > li:hover ul {
    left: auto;
    opacity: 1;

    li:hover {
      background: shade($brand-color--dark, 25%);
    }

    li:not(.ui-sortable-helper) {
      @include transition(background, .3s);
    }
  } // END ul > li:hover ul (dropdown)

  .queue__link,
  // .queue__remove,
  .flag-reading-queue,
  .favorite__reorder,
  .favorite__link,
  .favorite__remove {
    display: table-cell;
    vertical-align: middle;
  }

  .queue__link,
  .favorite__link {
    color: #fff;
    font-size: $milli-size;
    line-height: 1.2;
  }

  .queue__link,
  .favorite__link {
    width: 90%;
    padding: 10px 15px;
  }

  // .queue__remove,
  .flag-reading-queue,
  .favorite__reorder,
  .favorite__remove {
    width: 10%;
    color: #fff;
    text-align: center;
    opacity: .25;
    transition: opacity .3s;

    &:before {
      margin: 0 !important;
    }

    &:hover {
      opacity: 1;
    }
  }

  // Resolves doubled-up opacity
  .flag-link-toggle {
    color: #fff;
  }

  .remove-from-queue:before {
    margin: 0;
  }

  // TEMP REMOVE REORDERING
  .favorite__reorder {
    display: none;
  }

  .favorite__view-all {
    display: block;
    width: 100%;
    padding: 5px 0;
    color: white;
    font-size: $milli-size;
    text-align: center;
    text-transform: uppercase;
    opacity: .5;

    &:hover {
      opacity: .75;
    }
  } // END view-all link

  @media screen and (max-width: $tablet){
    float: none;
    display: block;
  }
} // END user-reading-queue / user-favorites


// Max height on RQ and
// Box shadow to designate scrolling
.user-reading-queue ul {
  max-height: 400px;
  // box-shadow: inset 0 -10px 15px -10px rgba(0, 0, 0, 0.5),
  //             inset 0 10px 15px -10px rgba(0, 0, 0, 0.5);
}


// Position Rel for Favorites
.reorder-wrapper {
  position: relative;

  .ui-sortable-helper {
    background: shade($brand-color--dark, 25%);
    border-top: 1px solid shade($brand-color--dark, 35%);
    // box-shadow: 0 0 5px rgba(0,0,0,.3);
  }
}



.research-tools-menu,
.publications-menu {
  float: right;

  .nav-link--personal__tools {
    cursor: pointer;
  }

  &:hover .nav-link--personal__tools {
    background: $brand-color-2--dark;
  }

  ul {
    @include position(absolute, 40px -9999px 0 0);
    width: 200px;
    padding: 20px 0;
    background: $brand-color-2--dark;
    opacity: 0;
    transition: opacity .3s;

    a {
      color: #eee;

      @media screen and (max-width: $tablet) {
        display: block;
        margin: 5px 0;
      }
    }

    @media screen and (max-width: $tablet) {
      display: none;
      position: relative;
      top: 0;
      right: 0;
      width: 100%;
      opacity: 1;
      box-shadow: inset 0 3px 10px rgba(0,0,0,0.2),
                  inset 0 -3px 10px rgba(0,0,0,.2);
    }
  }

  // Nav Item
  li {
    list-style: none;
  }

  > li:hover ul {
    right: 0px;
    opacity: 1;

    li {
      margin-bottom: 10px;
      line-height: 1;
    }

    a {
      font-size: $milli-size;
      color: #eee;

      &:hover {
        color: #fff;
      }
    }
  } // END ul > li:hover ul (dropdown)

  nav {
    @include span-columns(6);

    &:nth-child(1) {
      border-right: 1px solid #BA561C;
    }
    &:nth-child(2) {
      margin-left: -1px;
      border-left: 1px solid #BA561C;
    }

    @media screen and (max-width: $tablet) {
      float: none;
      width: 100%;
      margin-bottom: 30px;
    }
  } // Sub-nav lists

  .source__title {
    font-size: $milli-size;
    font-weight: $black;
    text-transform: uppercase;
    color: #fff;
  }

  @media screen and (max-width: $tablet){
    float: none;
    display: block;
  }
} // END personal tools

.publications-menu {
  .nav-link--personal__tools {
    border-right: 1px solid $brand-color-2--dark;
  }

  ul {
    width: 380px;

    @media screen and (max-width: $tablet) {
      width: 100%;
    }
  }

  > li:hover ul {
    right: -134px;

    @media screen and (max-width: $tablet) {
      right: 0;
    }
  }
}

.research-tools-menu ul li {
  display: block;
  width: 100%;
  padding: 0 20px;
}

// TEMP Public Preview Tab
.public-preview {
  position: absolute;
  top: 0;
  left: 0;
  color: #444;
  text-align: left;

  &:hover {
    .public-preview-content {
      top: 24px;
      opacity: 1;
    }
  }
}

.public-preview-tab {
  padding: 4px 10px;
  background: $brand-color-2;
  border-bottom: 1px solid $brand-color-2--dark;
  color: #fff;
  font-size: 10px;
  font-weight: $bold;
  text-transform: uppercase;
  cursor: pointer;

  .icon-info-circled {
    margin-left: 5px;

    &:before {
      margin-right: 0;
    }
  }
}

.public-preview-content {
  position: absolute;
  top: -200px;
  width: 400px;
  padding: 20px;
  background: $brand-color-2;
  border-bottom: 1px solid $brand-color-2--dark;
  opacity: 0;
  transition: opacity .3s;

  strong {
    display: block;
    margin-bottom: 10px;
    line-height: 1.2;
  }

  ul {
    margin-left: 20px;
    list-style: square;

    li {
      margin-bottom: 5px;
      line-height: 1.2;
    }
  }
}
