// ------------------------------------------------------------------- //
//  Profile / Account
// ------------------------------------------------------------------- //

.profile h1 {
  color: $brand-color-2;
  font-weight: $normal;
}


.subscriber__basic-info {
  @include row;
}

.basic-info__name {
  @include span-columns(6);
  font-size: 18px;
  font-weight: $bold;

  @media screen and (max-width: $mobile) {
    float: none;
    width: 100%;
  }
}

.basic-info__email,
.basic-info__company {
  @include span-columns(6, right);
  margin-bottom: 10px;

  &:before {
    display: inline-block;
    width: 20px;
    color: $brand-color-3b;
  }

  @media screen and (max-width: $mobile) {
    float: none;
    width: 100%;
  }
}

.contact-incorrect {
  clear: both;
  @include span-columns(6, right);
  margin: 20px 0 10px;
  color: $brand-color-3b;
  line-height: 1.2;

  @media screen and (max-width: $mobile) {
    float: none;
    width: 100%;
  }
}


#taProfile_form {
  @include row;

  #edit-account {
    clear: none;
  }

  .form-wrapper {
    @include span-columns(6);
    position: relative;

    &#edit-account {
      @include span-columns(12);
      @include hug(both);

      @media screen and (max-width: $mobile) {
        float: none;
      }
    }

    @media screen and (max-width: $mobile) {
      clear: both;
      float: none;
      width: 100%;
    }
  }

  .field-name-field-user-tax-topics,
  .field-name-field-user-jurisdictions,
  .field-name-field-user-industries,
  .field-name-field-primary-daily {
    @include span-columns(12);
  }

  .form-item-name .description {
    display: none;
  }

  .form-actions {
    @include span-columns(6, right);
    clear: both;
    text-align: right;

    input[value="Cancel"] {
      @include removeAppearance;
      margin-right: 15px;
      color: $brand-color-3c;
    }

    input[type="submit"] {
      @extend .btn--primary;
    }

    @media screen and (max-width: $mobile) {
      float: none;
      width: 100%;
    }
  }

  .taTooltip,
  .taTooltip--phone,
  .taTooltip--alias {
    position: absolute;
    top: -2px;
    right: 10px;
    color: #ccc;

    &:hover {
      color: #666;
    }
  }
} // END taProfile_form




// -------------------------------------------------------------------- //
//  Edit Account (Small Sub Form controlling email/password)
// -------------------------------------------------------------------- //

#edit-account {
  clear: both;

  .form-item-mail {
    @include span-columns(12);

    .description {
      display: none;
    }
  } // END form-item-mail

  .form-item-pass {
    clear: both;
    @include clearfix;

    .description,
    .password-suggestions,
    .password-strength-text,
    .password-strength-title,
    div.password-confirm {
      display: none !important;
    }

    .password-strength {
      float: none;
      position: relative;
      top: 21px;
      width: 100%;
      margin: 0;
    }
  } // END form-item-pass

  .form-item-pass-pass1,
  .form-item-pass-pass2 {
    position: relative;
    clear: none;
    @include span-columns(6);

    .password-indicator {
      margin-top: -2px; // Pull form inline
      height: 2px;
      background-color: $base-border-color;
    }

    @media screen and (max-width: $mobile) {
      float: none;
      width: 100%;
    }
  } // END form-item-pass-*
} // edit-account





// -------------------------------------------------------------------- //
//  Registration Form
// -------------------------------------------------------------------- //

#taRegistration_form {
  @include row;

  .form-wrapper {
    @include span-columns(6);

    &#edit-account {
      @include span-columns(12);
      @include hug(both);
    }

    @media screen and (max-width: $mobile) {
      float: none;
      width: 100%;
      padding: 0;
    }
  }

  .form-item-mollom-captcha,
  .terms-and-conditions,
  .form-actions {
    float: right !important;
    clear: both;
    width: 50%;
    padding: 0 20px;

    @media screen and (max-width: $mobile) {
      float: none;
      width: 100%;
      padding: 0;
    }
  }

  .form-item-mollom-captcha {
    margin-bottom: 20px;
  }

  .terms-and-conditions {
    text-align: right;
  }

  .form-actions {
    text-align: right;

    input[value="Cancel"] {
      @include removeAppearance;
      margin-right: 15px;
      color: $brand-color-3c;
    }

    input[type="submit"] {
      @extend .btn--primary;
    }
  }

  // -------------------------------------------------------------------- //
  //  IP Registration Form

  .profile-personalization {
    clear: both;
    width: 100%;
    margin: 15px 20px 0;
    padding-top: 15px;

    .form-wrapper {
      @include span-columns(12);
      @include hug(both);
    }

    @media screen and (max-width: $mobile) {
      margin: 15px 0;
    }
  }

  @media screen and (max-width: $mobile) {
    margin: 0;

    #edit-account .form-item {
      padding: 0;
    }
  }
} // END taRegistration_form





// ------------------------------------------------------------------- //
//  Profile Page
// ------------------------------------------------------------------- //

.profile__subscribe {
  @extend %vertical-rhythm;
  @include clearfix;
  padding: 25px 70px;
  border: 5px solid $brand-color-2;
  border-radius: 5px;

  .article-views-remaining {
    margin-bottom: 10px;
    color: $brand-color-3c;
    font-size: $h5-size;
    font-weight: $normal;
    text-align: center;

    @media screen and (max-width: $mobile) {
      line-height: 1;
    }
  }

  .article-views-remaining-count {
    color: $brand-color-3b;
    font-weight: $bold;
  }

  h1 {
    color: $brand-color-2;
    margin-bottom: 25px;
    font-weight: $black;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: $mobile) {
      margin-bottom: 15px;
      font-size: 25px;
      line-height: 1;
    }
  }

  .profile__subscribe__extra,
  .profile__subscribe__action {
    @include span-columns(6);

    @media screen and (max-width: $mobile) {
      float: none;
      width: 100%;
      padding: 0;
      text-align: center;
    }
  }

  .profile__subscribe__extra {
    @include hug(left);
    color: $brand-color-3c;
    font-size: $tiny-size;
    font-weight: $black;
    text-transform: uppercase;

    p,
    small {
      margin-bottom: 0;
      line-height: 1;
    }

    .view-reset-date {
      color: $brand-color-3b;
    }
  }

  .profile__subscribe__action {
    @include hug(right);

    .btn--primary {
      float: right;
      @include size(185px 50px);
      font-size: $base-font-size;
      line-height: 35px;

      &:after {
        font-size: 16px;
      }

      @media screen and (max-width: $mobile) {
        width: 100%;
        height: 40px;
        margin-top: 10px;
        padding: 0;
        
        &:after {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: $mobile) {
    padding: 10px;
  }
} // END profile__subscribe

.help-personalize {
  clear: both;
  padding: 20px 0 0;
  border-top: 1px solid $base-border-color;
  color: #666;
  font-size: $h2-size;
  font-weight: 700;
}





// ------------------------------------------------------------------- //
//  Favorites / Saved Searched / STAP
// ------------------------------------------------------------------- //

.page-user-manage-favorites,
.page-user-manage-searches,
.page-state-tax-amnesty-programs {
  // Contain content area
  .block-system .content {
    @include outer-container;
  }

  // Force H2 to act like other page titles
  h2.title {
    margin-bottom: 20px;
    color: $brand-color-2;
    font-size: $h1-size;
    font-weight: $normal;
  }
} // END page-user-favorites





// -------------------------------------------------------------------- //
//  Manage Email Subscriptions
// -------------------------------------------------------------------- //

.subscriptions-no-results {
  color: #999;
  font-size: 12px;
  font-style: italic;
}

.manage-email-subscriptions {
  h1 {
    margin-bottom: 10px;
    color: $brand-color-2;
  }
}

.manage-subscriptions__options {
  margin: 0 -20px;

  > .form-item {
    @include span-columns(3);
    margin-bottom: 35px;

    // Section label
    > label {
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-bottom: 2px solid $base-border-color;
    }
  }

  .form-checkboxes .form-item {
    margin-top: 0;
    margin-bottom: 15px;
  }

  // Options
  .form-item input {
    float: left;
    margin-right: 10px;

    // Option labels
    + label {
      display: block;
      overflow: hidden;
      text-transform: none;
    }
  } // END form-item input

  // Submit
  .form-submit {
    @extend .btn--primary;
    clear: both;
    display: block;
    margin-bottom: 35px;
    margin-left: 20px;
  }
}

.subscription-label {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.2;
}

.subscription-description {
  color: #999;
}



// -------------------------------------------------------------------- //
//  Custom Emails

.manage-subscriptions__custom {
  margin: 60px 20px 0;

  + .subscriptions-no-results {
    margin: 0 20px 30px;
  }
}

.custom-email-subscription {
  margin: 0 20px;
  padding: 15px 0;
  border-bottom: 1px solid $base-border-color;

  &:first-child {
    border-top: 1px solid $base-border-color;
  }

  + .form-submit {
    margin-top: 30px;
  }

  // Each line
  > .form-item {
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;
    justify-content: flex-start;

    > label {
      flex-basis: 30%;
      margin-bottom: 0;
      font-size: 18px;
      line-height: 1.2;
      text-transform: none;

      // Included filters
      span {
        display: block;
        margin-top: 5px;
        color: #999;
        font-size: 12px;
        font-weight: 400;
      }
    } // END label

    .form-radios {
      .form-item {
        display: inline-block;
        margin-right: 20px;
        cursor: pointer;

        label {
          float: left;
        }
      }
    }
  } // END form-item
}





// ------------------------------------------------------------------- //
//  Contact Us
// ------------------------------------------------------------------- //

#ta-contact-form {
  margin-top: 15px;

  .form-item {
    float: left;
    width: 315px;

    &:nth-child(even) {
      margin-right: 15px;
    }
  }

  .form-textarea {
    margin-bottom: 15px;
  }

  .form-item-mollom-captcha {
    margin-left: 15px;
    margin-right: 15px;
  }

  input[type="submit"] {
    @extend .btn--primary;
    float: right;
    clear: both;
    margin-bottom: 30px;
  }
}

.contact-us__method {
  margin-bottom: 5px;
}

.customer-service--phone {
  p:first-child {
    float: left;
    margin-right: 5px;

    + p {
      display: block;
      overflow: hidden;
      margin-bottom: 0;
    }
  }
}

.contact-us__extra {
  a {
    display: block;
    margin-bottom: 5px;
  }
}
