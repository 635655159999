// ------------------------------------------------------------------- //
//  Article
// ------------------------------------------------------------------- //

article {
  position: relative;
}

.breadcrumbs {
  @extend %breadcrumb;
  margin: -20px 0 10px;
  font-family: $brand-font-family;
  font-size: $mini-size;
  text-transform: uppercase;

  a:first-child {
    @extend %breadcrumb__root;
  }

  a {
    color: $brand-color-3c;
    font-weight: 600;
  }

  @media screen and (max-width: $mobile) {
    padding: 0 20px;
  }
} // END breadcrumbs

.article__nav,
.article-pdf__nav {
  @extend %info-bar--nav;
} // END article__nav

.article-pdf__nav {
  margin: 15px 0 0;
}

hgroup {
  margin-bottom: 20px;

  .article__primary,
  .article__secondary,
  .article__tertiary{
    color: $brand-color-3c;
    font-weight: $normal;
    letter-spacing: 0;
    line-height: 1;
    text-transform: none;
  }

  .article__title{
    color: $brand-color-2;
    font-size: 28px;
    font-weight: $normal;
    line-height: 1;
  }
} // END hgroup


// -------------------------------------------------------------------- //
//  Article Bar

.article__bar {
  height: 40px;
  margin-bottom: 20px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  color: #999;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 11px;
  line-height: 1;
  
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  @media screen and (max-width: $mobile) {
    height: auto;
  }
}

.article__posted {
  float: left;
  display: flex;
  align-items: center;
  height: 38px;
  font-weight: 700;
  text-transform: uppercase;
  
  p {
    margin: 0;

    @media screen and (max-width: $mobile) {
      width: 100%;
      text-align: center;
    }
  }

  a {
    color: #999;

    &:hover {
      color: #666;
    }
  }

  @media screen and (max-width: $mobile) {
    width: 100%;
    border: solid #ccc;
    border-width: 0 1px 1px;
  }
}

.article__actions {
  float: right;

  @media screen and (max-width: $mobile) {
    float: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    border: solid #ccc;
    border-width: 0 1px;

    a:first-child {
      border-left: 0;
    }
  }
}

.article__favorite,
.article__print,
.article__pdf,
.article__social {
  float: left;
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 10px;
  border-left: 1px solid #ccc;
  color: #999;
  vertical-align: middle;
  
  span {
    float: left;
    margin-right: 5px;

    &:before {
      margin-right: 0;
    }
  }

  &:hover {
    color: #666;
  }

  @media screen and (max-width: $mobile) {
    flex: 1 1 30%;
    justify-content: center;
  }
}

// Favorited
.ta-delete-favorite-up {
  color: $brand-color-2;
}

.article__social {
  // Override default AT styles
  #atstbx {
    display: flex !important;
    align-items: center;
    height: 40px;
  }

  @media screen and (max-width: $mobile) {
    flex-basis: 100%;
    border-top: 1px solid #ccc;
    border-left: 0;
  }
}

.article__bar--mini {
  margin: 30px auto 60px;
  text-align: center;

  a {
    display: inline-block;
    width: 20px;
    color: $brand-color-3b;

    &:before{
      margin: 0;
    }
  }

  .article__social {
    display: block;
    float: none;
    height: auto;
    margin-top: 5px;
    padding: 0;
    border: 0;
  }
}


// -------------------------------------------------------------------- //
//  Article Summary

.article__ta-summary {
  padding: 25px 20px;
  background: #eee;

  strong {
    display: block;
    margin-bottom: 10px;
    color: $brand-color;
    font-family: $brand-font-family;
    font-size: $tiny-size;
    font-weight: $black;
    line-height: 1;
    text-transform: uppercase;
  }

  p:last-child {
    margin-bottom: 0;
  }
} // END ta-summary

.article__ta-title,
.article__ta-case-name {
  font-weight: $bold;
  text-align: center;
}

.article__ta-full-text-label strong {
  display: block;
  margin-bottom: 10px;
  color: $brand-color;
  font-family: $brand-font-family;
  font-size: $tiny-size;
  font-weight: $black;
  line-height: 1;
  text-transform: uppercase;
}

.article__ta-full-text {
  margin-bottom: 50px;

  // TEMP QUICK FIX for missing spaces
  // in some aspects of full text
  p {
    margin-top: 20px;
    margin-bottom: 0;
  }

  & > p {
    &:empty + p,
    &:first-of-type {
      margin-top: 0;

      // If image in first paragraph,
      // remove top margin
      .tapic,
      .tacappic {
        margin-top: 0;
      }
    }
  }

  ul {
    // margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  & > div {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  figure {
    overflow: hidden;
    margin: 20px;
    border: 1px solid $base-border-color;
  }

  figcaption {
    padding: 15px 25px;
    background-color: #eee;
    border-top: 1px solid $base-border-color;
    color: #666;
    font-family: $brand-font-family;
    font-size: $micro-size;
    font-weight: 900;
    text-transform: uppercase;
  }

  .ta-article__img--left {
    float: left;
    clear: both;
    margin-left: 0;

    figcaption {
      float: left;
    }
  }

  .ta-article__img--right {
    float: right;
    clear: both;
    margin-right: 0;

    figcaption {
      float: right;
    }
  }

  // Footnotes spacing issue
  sup + i {
    margin-left: 4px;
  }
} // END article__ta-full-text

.print-link {
  display: none;
}





// ------------------------------------------------------------------- //
//  Article Byline / Authors TEMPORARY
// ------------------------------------------------------------------- //

.byline {
  float: left;
  width: 30%;
  margin: 0 20px 20px 0;
  padding-right: 10px;
  border-right: 1px solid $base-border-color;
  font-family: $brand-font-family;

  .byline-label {
    margin-bottom: 10px;
    color: #999;
    font-size: $micro-size;
    font-weight: $bold;
  }

  .author {
    display: table;
    min-height: 35px;
    margin-bottom: 2px;

    &-img,
    &-name {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .author-img {
    @include size(35px);

    + .author-name {
      padding-left: 5px;
    }
  }

  .author-name {
    padding-left: 5px;
    font-size: $milli-size;
    font-weight: $bold;
    line-height: 1;
    text-transform: uppercase;

    a {
      color: $brand-color-3c;
    }
  }

  @media screen and (max-width: $mobile) {
    float: none;
    width: 100%;
    margin-right: 0;
    border-right: 0;
  }
}






// ------------------------------------------------------------------- //
//  Article Content Settings
// ------------------------------------------------------------------- //

.metadata-row {
  @include clearfix;
  display: block;
  padding: 5px 0;
  border-bottom: 1px solid $base-border-color;
} // END metadata-row

.metadata__label,
.metadata__results {
  @include span-columns(6);
}

.metadata__label {
  @include hug(left);
  color: $brand-color-3c;
  font-size: $milli-size;
  text-transform: uppercase;
} // END metadata__label

.metadata__results {
  font-size: $micro-size;
  text-transform: uppercase;

  & > span {
    display: inline-block;
    margin: 2px;
    padding: 3px 5px;
    background: $brand-color-3e;
    line-height: 1.2;
  }

  a,
  a:visited {
    color: $brand-color;
  }
} // END metadata__results



.article-pdf__content {
  width: 100%;
  border: 1px solid $base-border-color;
}





// ------------------------------------------------------------------- //
//  Article Comments
// ------------------------------------------------------------------- //

.article__comments,
.article__submit-comment,
.article__attributes {
  .panel {
    height: 0;
    overflow: hidden;

    &.expanded {
      height: auto;
      overflow: visible;
    }
  }
}



.article__submit-comment .panel {
  position: relative;

  &.expanded {
    padding: 20px;
    background: $brand-color-3e;
    border: solid $base-border-color;
    border-width: 0 1px 1px;
  }

  .field-name-comment-body {
    margin-bottom: 20px;
  }

  // Hide advanced help attached to comment block
  .filter-wrapper {
    display: none;
  }

  .required-text {
    font-family: $brand-font-family;
    font-weight: $black;
    text-transform: uppercase;
  }
}

.submit-comment__intro {
  color: $brand-color-3c;
  font-size: $milli-size;
}

.submit-comment__notify {
  margin-bottom: 20px;

  label {
    text-transform: none;
  }
}


.comment {
  padding: 20px 0 20px 10px;
  border: solid $base-border-color;
  border-width: 0 0 1px 1px;
  font-size: $milli-size;
}

.comment__author {
  margin-bottom: 10px;
  font-family: $brand-font-family;
  font-weight: $bold;
  text-transform: uppercase;

  a {
    color: $brand-color-3a;
  }
}

.comment__actions {
  text-transform: uppercase;

  .links li {
    margin-right: 15px;
    padding: 0;
  }

  a {
    color: $brand-color-3c;

    &:hover {
      color: $brand-color-3b;
    }
  }

  li:empty {
    display: none !important;
  }
}





// ------------------------------------------------------------------- //
//  Article Footer Nav (Blue Bar)
// ------------------------------------------------------------------- //

.article__nav--footer {
  @include clearfix;

  .previous-article,
  .next-article {
    float: left;
    width: 50%;
    padding: 30px 0;
    background: $brand-color;
    transition: background .3s;

    &:hover {
      background: #1A74AB;
    }

    &.inactive {
      background: #1A74AB;
    }

    @include max-screen($mobile) {
      float: none;
      width: 100%;
      padding: 30px;
    }

    @include screen($mobile, $tablet) {
      padding: 30px;
    }
  }

  .previous-article {
    border-right: 1px solid $brand-color--light;
  }

  .previous-article__content,
  .next-article__content {
    max-width: 465px;

    & > a {
      display: block;
      margin-bottom: 25px;
      color: #80B2CF;
      font-weight: bold;
    }
  }

  .previous-article__content {
    float: right;
    padding-right: 45px;

    @include max-screen($tablet) {
      padding-right: 0;
    }
  }

  .next-article__content {
    float: left;
    padding-left: 45px;

    @include max-screen($tablet) {
      padding-left: 0;
    }
  }

  .media__title a,
  .media__snippet {
    color: #fff;
  }

  .media__title {
    clear: both;
  }

  .media__snippet {
    margin-bottom: 0;
    font-size: $milli-size;
  }

  .previous-link,
  .next-link {
    display: block;
    margin-bottom: 15px;
    color: #80B2CF;
    font-size: $milli-size;
    font-weight: 600;
    text-transform: uppercase;
  }

  .icon-left-dir {
    margin-left: -17px;

    @include max-screen($tablet) {
      margin-left: 0;
    }
  }

  .icon-right-dir {
    float: right;
    margin-right: -25px;

    &:before {
      float: right;
      margin-left: 10px;
      line-height: 20px;
    }

    @include max-screen($tablet) {
      margin-right: 0;
    }
  }
} // END article__nav--footer
