// ------------------------------------------------------------------- //
//  Browser prefixing
//
//  @include prefixer(border-radius, 5px, webkit ms spec);
// ------------------------------------------------------------------- //

$prefix-webkit:  true !default;
$prefix-mozilla: true !default;
$prefix-ms:      true !default;
$prefix-opera:   true !default;
$prefix-spec:    true !default;

@mixin prefixer($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    @if $prefix == webkit {
      -webkit-#{$property}: $value;
    }
    @else if $prefix == moz {
      -moz-#{$property}: $value;
    }
    @else if $prefix == ms {
      -ms-#{$property}: $value;
    }
    @else if $prefix == o {
      -o-#{$property}: $value;
    }
    @else if $prefix == spec {
      #{$property}: $value;
    }
    @else {
      @warn "Unrecognized prefix: #{$prefix}";
    }
  }
}