// ------------------------------------------------------------------- //
//  Blog Styles
// ------------------------------------------------------------------- //

.page-blog .landing__title,
.page-blogger-profile .landing__title {
  background: $brand-color-blog;
}

.blog-list {
  .content-block__header {
    border-bottom-color: $brand-color-blog;
  }

  .content-block__title {
    color: $brand-color-blog;
  }
}

.media--blog {
  @extend .media;

  .media__image {
    @include size(55px);
    margin-top: 20px;
  }

  .media__datetime {
    margin-bottom: 5px;
  }

  .media__title {
    font-size: 18px;
    font-weight: $bold;

    a {
      color: $brand-color-blog;
    }
  }

  .media__author {
    margin-top: 5px;
  }

  .media__snippet {
    margin-top: 10px;
  }
} // END media--blog


// ------------------------------------------------------------------- //
//  Blogger Page

.search--blogger {
  @include clearfix;
}

.search--blogger__photo {
  float: left;
  display: block;
  max-width: 100px;
  margin-right: 20px;

  img {
    width: 100%;
    height: auto;
  }
}

.search--blogger__content {
  display: block;
  overflow: hidden;
}

// .listing .media--blogger {
//   @extend .media;
//   margin: 20px;
//   padding: 0;
//   border-bottom: 0;

//   .media__image,        // Resize container
//   .contributor__image { // and actual image
//     @include size(55px);
//   }

//   .media__content {
//     padding: 0 20px;
//   }
// }

// .blog-profile {
//   .content-block__header {
//     border-bottom-color: $brand-color-blog;
//   }

//   .content-block__title {
//     color: $brand-color-blog;
//   }

//   .media__title {
//     font-size: 18px;
//     font-weight: $bold;

//     + .media__snippet {
//       margin-top: 10px;
//     }
//   }
// }
