// ------------------------------------------------------------------- //
//  LookThink Normalization Sassified
// ------------------------------------------------------------------- //
// ------------------------------------------------------------------- //
//  Meyer's Style Reset
// ------------------------------------------------------------------- //

html, body, div, span, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, hr,
a, abbr, address, cite, code,
em, img, small, strong, sub, sup,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
}

// ------------------------------------------------------------------- //
//  Border-Box
// ------------------------------------------------------------------- //

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

// ------------------------------------------------------------------- //
//  Display Definitions
// ------------------------------------------------------------------- //

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

// ------------------------------------------------------------------- //
//  Base Level
// ------------------------------------------------------------------- //

html {
  max-width: 100%;
  overflow-x: hidden;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  max-width: 100%;
  margin: 0;
}

// ------------------------------------------------------------------- //
//  Links
// ------------------------------------------------------------------- //

a {
  background: transparent;

  &:focus {
    outline: thin dotted;
  }
  &:hover,
  &:active {
    outline: 0;
  }
}

// ------------------------------------------------------------------- //
//  Typography
// ------------------------------------------------------------------- //

abbr[title] {
  border-bottom: 1px dotted;
}

address {
  @extend %vertical-rhythm;
}

strong {
  font-weight: bold;
}

hr {
  height: 0;
}

code,
pre {
  @extend %vertical-rhythm;
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-size: 1em;
}

pre {
  white-space: pre-wrap;
}

p {
  @extend %vertical-rhythm;
}

small {
  font-size: 75%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}

// ------------------------------------------------------------------- //
//  Embedded Content
// ------------------------------------------------------------------- //

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

// ------------------------------------------------------------------- //
//  Forms
// ------------------------------------------------------------------- //

#{$all-text-inputs}, // http://bourbon.io/docs/#html5-input-types
button,
select,
textarea {
  margin: 0;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

// ------------------------------------------------------------------- //
//  Lists
// ------------------------------------------------------------------- //

ul,
ol,
dl {
  @extend %vertical-rhythm;
  padding: 0;
}

li > ul,
li > ol {
  margin-bottom: 0;
  padding-bottom: 0;
}

// ------------------------------------------------------------------- //
//  Tables
// ------------------------------------------------------------------- //

table {
  @extend %vertical-rhythm;
  border-collapse: collapse;
  border-spacing: 0;

  th,
  td {
    padding: $base-line-height / 2;
  }
}
