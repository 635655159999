/*header*/

.page--nonsub {
	font-family: 'Open Sans', sans-serif;

	h1, h2, h3, h4 {
		font-family: 'Open Sans', sans-serif;
	}

	.floatr {
		float: right;
	}

	.floatl {
		float: left;
	}

	figure img {
		display: block;
	}


	ul {
		margin: 0;
		padding: 0;
	}

	.wrap__content {
		@include clearfix;
		padding: 0 24px;
		margin: 0 auto;
		width: 100%;
		max-width: 960px;

		@include respond-min($tablet) {
			padding: 0;
		}
	}

	nav ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.wrap__content_medium {
		@extend .wrap__content;
		max-width: 800px;
	}

	header{
		padding: 18px 0px 22px;

	}
 
	header figure {
		float: left;

		@include respond-max($tablet) {
			max-width: 130px;
		}

		img {
			max-width: 100%;
		}
	}

	header .header-actions {
		//position: absolute;
		float: right;
		margin-top: 4px;
		
		// form {
		// 	margin-top: 15px;
		// 	width: 50%;
		// 	margin-bottom: 0;
		// 	padding-bottom: 0;

		// 	.search {
		// 		position: relative;
		// 		left: 188px;
		// 		bottom: 43px;
		// 	}
		// }
		
	}

	header .login-link {
		font-size: 13px;
		font-weight: 600;
		line-height: 15.6px; 
		color:#666;
		float:left;
		padding: 0 15px 0 0;
		margin: 0;
	}

	header .login-link a {
		//display: none;
		margin-left: 3px;
		text-transform: uppercase;
		font-weight: 700;

		@include respond-max($tablet) {
			display: block;
			color: #1A73A9;
			font-size: 12px;
			font-weight: bold;
			text-transform: uppercase;
			text-decoration: none;
		}
	}

	.btn {
		display: inline-block;
		text-align: center;
	}

	.sign_in {
		float: right;
		font-size: 22px;

		@include respond-max($tablet) {
			background: #0D64A3;
			text-transform: uppercase;
			font-weight: 700;
			padding: 8px 16px;
			text-transform: uppercase;
			text-decoration: none;

			&:before {
				content: 'Login';
				display: block;
				color: white;
				font-size: 12px;
				line-height: 1.2;
			}

			.fa-sign-in {
				display: none;
			}
		}

	}

	/*end header*/

	/*long scroll section 1*/

	.ls-section--one{
		position: relative;
		text-align: center;

		@include respond-min($tablet) {
			height: 480px;
		}

		.bg__wrap {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;

			&:before,
			&:after {
				@include transition(opacity 0.4s $ease-in-out-sine);
				content: '';
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}

			&:before {
				background: #221F1F;
				z-index: -3;
			}

			&:after {
				background: transparent url('../images/hero-img.jpg') 0 0;
				background-size: cover;
				z-index: -2;
			}

			@include respond-max($tablet - 1) {
				&:after {
					opacity: .5;
				}
			}
		}
	}


	.ls-section--one h1{
		@include transition(font-size 0.4s $ease-in-out-sine, text-shadow 0.4s $ease-in-out-sine);
		padding-top: 34px;
		color:#fff;
		font-size: 36px;
		line-height: 1.166666667;

		@include respond-min($tablet) {
			margin-bottom: 26px;
			padding-top: 145px;
			font-weight: 300;
			font-size: 48px;
			text-shadow: 1px 1px 10px rgba(#000, .75);
		}
	}

	.ls-section--one p {
		font-size: 20px;
		line-height: 1.375;
		color:#fff;
		max-width: 635px;
		margin: 0 auto 32px;
		
		small {
			font-size: 12px;
			font-weight: 600;

			a {
				color: #fff;
				text-decoration: underline;
			}
		}
		@include respond-max($tablet - 1) {
			margin-top: 12px;
		}
		
		@include respond-min($tablet) {
			font-size: 24px;
			line-height: 1.25;
			font-weight: 400;
			text-shadow: 1px 1px 5px #000;
		}
	}

	.ls-section--one a {
		//margin-bottom: 16px;
		font-weight: 700;
	}
	/*end long scroll section one */

	/*stats header*/

	.stats-header{
		margin-bottom: 32px;
		padding: 18.5px 0;
		text-align: center;
		background-color:#0064A0;
		
		@include respond-min($tablet) {
			padding: 18.5px 0;
		}
	}

	.stats-header h2 {
		font-weight: 400;
		font-size: 24px;
		line-height: 1.2;
		color:#fff;


	}

	/*end stats header*/

	/*stats facts*/

	.stats-facts {
		text-align:center;
		background-color:#1A73A9;
		padding: 0 0 32.5px;
	}

	.stats-facts ul {
		overflow: hidden;
		margin-bottom: 0;
	}

	.stats-facts li {
		display: block;
		margin-bottom: 34px;

		
		@include respond-min($tablet) {
			float: left;
			max-width: 300px;
			width: 100%;
			margin-right: 30px;
			margin-bottom: 0;
		}
	}

	.stats-facts li:first-of-type {
		@include respond-min($tablet) {
		  margin-right: 0px;
  		max-width: 330px;
  	}
  	
		p {
			@include respond-min($tablet) {
				max-width: 134px;
			}
		}
	}

	.stats-facts li:last-of-type {
		margin: 0;

		@include respond-min($tablet) {
			float: right;
		}
	}

	.stats-facts .tightenUp {
		letter-spacing: -6px;
	}
	.stats-facts li p,
	.stats-facts li strong {
		display: inline-block;
		letter-spacing: -1px;
		vertical-align: middle;
		margin-bottom: 0;
	}

	.stats-facts li strong{
		font-family: 'Lato', sans-serif;
		font-size: 45px;
		opacity: .9;
		color: #fff;
		margin-right: 10px;
		line-height: 1.0;

		@include respond-max($tablet - 1) {
			min-width: 102px;
			text-align: right;
		}

		@include respond-min($tablet) {
			margin-right: 8px;
			font-size: 58px;
		}
	}

	.stats-facts li p {
		max-width: 153px;
		font-size: 20px;
		font-weight: bold;
		line-height: 1.086956522;
		opacity: .6;
		color: #c0c0c0;
		text-align: left;
		text-transform: uppercase;

		@include respond-min($tablet) {
			max-width: 156px;
			font-size: 22px;
		}
	}




	/*end stats facts*/

.video-section {
	background: url('../images/video-bg.jpg') no-repeat;
	background-size: cover;
	padding: 44px 0 52px;
	
	@include respond-min($tablet) {
		padding: 44px 0 52px;
	}

	.wrap__content {
		max-width: 556px;
	}

	.list__videos {
		@include pad-ratio(16,9, li);
		@include pad-ratio(16,9, li iframe);
		margin-bottom: 8px;
	}

	.list__videos li {
		transition: opacity .25s $ease-in-out-sine;
		display: none;

		&.is--active {
			animation-name: showFromDisplayNone;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
			opacity: 1;
			display: block;
		}
	}

	.nav__video_list ul {
		@include respond-min($tablet) {
			display: flex;
		}
	}

	.nav__video_list li {
		margin-bottom: 10px;

		@include respond-min($tablet) {
			margin-right: 5px;
			margin-bottom: 0;

			&:last-of-type {
				margin-right: 0;
				flex-grow: 2;
			}
		}
	}

	.nav__video_list a {
		transition: background-color .5s $ease-in-out-sine, border-top .5s $ease-in-out-sine;
		display: block;
		padding: 10px 16px 12px;
		min-width: 117px;
		border-top: 4px solid #1A73A9;
		background: #1A73A9;
		color: white;
		font-size: 16px;
		font-weight: bold;
		text-align: center;

		&:hover {
			background: #F07320;
			border-top-color: #C56220;
		}
	}
}

.list__videos {
	list-style: none;
	margin: 0;
	padding: 0;
}

	/*tax facts*/

	.tax-facts {
		padding: 56px 0 65px;
	}

	.tax-facts h2 {

		font-weight: 500;
		font-size: 18px;	

		line-height: 34px;
		color:#0064a0;
		text-align:center;
		margin-bottom: 32px;

		@include respond-min($tablet) {
			font-size: 30px;
			padding: 0 80px;
		}
	}

	.tax-descriptions li:not(:last-child) {
		@include respond-min($tablet) {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				right: -14px;
				top: 0;
				bottom: 0;
				border-right: 1px solid #c1c1c1;
				height: 100%;
			}
		}
	}

	.tax-descriptions ul li {
		display: block;
		//max-width: 300px;
		//width: 100%;
		
		//margin-left: auto;
		text-align: center;

		p {
			font-weight: 400;
		}


	
		@include respond-min($tablet) {
			margin-right: 26px;
			float: left;
			margin-left: auto;
			//margin-right: 30px;
		}
	}

	.tax-descriptions ul li:last-of-type,
	 .tax-descriptions ul li.last{
		@include respond-min($tablet) {
			margin-right: 0;
		}
	}

	.tax-descriptions li h3 {
		font-size: 24px;
		font-weight: 600;
		line-height: .875;
		color: #0064a0;
		margin-bottom: 18px;

	}
	

	.tax-descriptions li p span {
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color:#666;
	}


	.tax-descriptions li p {
		display: none;

		@include respond-min($tablet) {
			display: block;
			font-weight: 300;
			font-size: 16px;
			line-height: 1.3125;
			color:#0064a0;
			margin-bottom: 3px;
		}
	}

	.tax-descriptions li a {
		display: none;
		
		@include respond-min($tablet) {
			margin-top: 35px;
			display: inline-block;
		}
	}

	
	.tax-descriptions ul li.fact-three a {
		display: none;
		
		@include respond-min($tablet) {
			margin-top: 15px;
			display: inline-block;
		}
	}

	.tax-descriptions .show__mobile {
		text-align: center;
	}

	.ls-section--three .btn {
		margin-top: 22px;
		display: inline-block;
	}

	/*end tax descriptions*/ 

	/*slider section*/

	.slider {
		display: none;
		background-color:#eff0f0;

		@include respond-min($tablet) {
			display: block;
		}

		.lt-ie9 & .bx-viewport {
			min-height: 177px;
		}
	}

	.bx-wrapper .bx-pager.bx-default-pager a {
		border: 2px solid #C1C1C1;
		background-color: transparent;
	}

	.bx-wrapper .bx-pager.bx-default-pager a.active,
	.bx-wrapper .bx-pager.bx-default-pager a:focus,
	.bx-wrapper .bx-pager.bx-default-pager a:hover {
		background: #C1C1C1;
	}

	.bx-wrapper {
	    background-color: transparent;
	    border: none;
	    box-shadow: none;

	    .bx-controls-direction {
	    	a {
				width: 16px;
				height: 26px;
	    	}

	    	.bx-prev,
	    	.bx-next {
	    		background: url('../images/controls.png') 0 0 no-repeat;
	    	}

	    	.bx-prev {
	    		background-position: 0 0;
	    	}

	    	.bx-next {
	    		background-position: -16px 0;
	    	}
	    }
	}

	.wrap--slider .testimonials {
		text-align: center;
	}

	.wrap--slider .testimonials {
		padding: 52px 0 10px;
	}

	.testimonials {
		blockquote {
			margin: 0 auto;
			padding-left: 0;
			max-width: 540px;
			border: none;

			p {
				color: #f07320;
				font-weight: 700;
			}
		}

		cite {
			display: block;
			font-size: 12px;
			font-weight: 400;

			strong {
				display: block;
				font-style: normal;
				font-size: 13px;
				font-weight: 600;
	
				&:before {
					content: '-';
				}
			}
		}
	}

	/*end slider section*/

	/*long scroll section two*/

	.ls-section--two {
		background: url('../images/ls-renewal.jpg') no-repeat #231f20;
		background-size: cover;
		text-align:left;
		background-color:#231f20;
		padding: 40px 0;
		
		@include respond-min($tablet) {
			padding: 126px 0 140px;
		}

		.lt-ie9 & {
			padding: 126px 0 90px;
		}

		ul {
			list-style: none;
		}

		li {
			position: relative;
			max-width: 368px;
			font-weight: 300;
			font-size: 20px;
			line-height: 28px;
			color:#fff;
			margin-top:5px;
			margin-bottom:10px;
			padding-left: 35px;
			margin-bottom: 39px;

			@include respond-min($tablet) {
				font-size: 22px;
				margin-left: 15px;
			}
			
			&:before {
				position: absolute;
				content: '\f00c';
				top: 0;
				left: 0;
				display: inline-block;
				font: normal normal normal 14px/1 'FontAwesome';
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				transform: translate(0, 0);
			}
		}
	}

	.hero-two--header {
		line-height: 1.227272727;
		color:#fff;
		max-width: 504px;
		margin-bottom: 32px;
		
		h2 {
			text-align: left;
			font-size: 28px;
			font-weight: 400;
			
			@include respond-min ($tablet) {
				font-size: 44px;
			}
		}
	}

	.ls-section--two-btn{
		//text-align: center;
		text-align: left;
		margin-top: 40px;

		@include respond-min($tablet) {
			margin-top: 0;
			margin-left: 35px; 
			text-align: initial
		}

		.btn:first-of-type {
			margin-right: 20px;
		}
	}

	/*end long scrolls section two*/

	/*trusted stats section*/

	.trusted-stats {
		display: none;
		background-color:#4189b5;
		//padding: 0px 0;

		@include respond-min($tablet) {
			display: block;
			//vertical-align: middle;
		}
	
		
	}


	.trusted-stats h2{
		text-align: center;
		vertical-align: middle;
		font-weight: 400;
		font-size: 26px;
		line-height: 1.178571429;
		color:#fff;
		max-width: 250px;
		width: 100%;
		//* position: relative;
		//* bottom: 21px;
		//* left: 247px;
		
		@include respond-min($tablet) {
			margin-left: 269px;
			padding-top: 45px;
			padding-bottom: 25px;
		}
	}

	.trusted-stats ul {
		text-align: center;
		padding-bottom: 40px;

	}
	.trusted-stats li {
		line-height: 1.5;
		position: relative;
		color: #fff;
		display: inline-block;
		font-weight: 900;
		font-size: 27px;
		padding-right: 15px;
		text-transform: uppercase;
		letter-spacing: -1px;
		
		#circle {
			position: absolute;
			bottom: 5px;
			width: 8px;
			height: 8px;
			background: #c1c1c1;
			-moz-border-radius: 8px;
			-webkit-border-radius: 8px;
			border-radius: 8px;
		}
	}

	  

		/*end of trusted stats*/


.quotes-header {
	
	
		 h2 {
			margin-top: 0px;
  			padding-top: 20px;
  			margin-bottom: -27px;
			font-weight: 900;
			font-size: 25px;
			line-height: 1.19047619;
			color:#0064a0;
			background: #EFEFF0;
			
			text-align: center;
			text-transform:uppercase;

		}
}
	/*quotes section*/


.quotes {
	
	background: #EFEFF0;
	padding:0;
	color: #0064a0;

	@include respond-max($tablet) {
		padding: 40px 0 0px;
	}
	//background-color:#eff0f0
}

	.bx-wrapper .bx-pager.bx-default-pager a{
		@include respond-max($tablet) {
	    			display: none;
	    		}
	}

	.bx-wrapper .bx-pager.bx-default-pager a.active,
	.bx-wrapper .bx-pager.bx-default-pager a:focus,
	.bx-wrapper .bx-pager.bx-default-pager a:hover {
		//display: none;
	
			background: #C1C1C1;
			@include respond-max($tablet) {
	    			display: none;
	    		}
		}
	

	.bx-wrapper {
	//	display:none;
			//@include respond-min($tablet) {
	    		background-color: transparent;
	    		border: none;
	    		box-shadow: none;

	    	.bx-controls-direction {
	    		.bx-prev,
	    		.bx-next {
	    			background: url('../images/controls.png') no-repeat;
	    		}

	    		.bx-next {
	    			transform: rotate(180deg);
	    		}

	    		@include respond-max($tablet) {
	    			display: none;
	    		}
	    		}

	    		
	    	//}
	   	}
		

	.wrap--slider .quote-slider {
		//display: none;
		
			//@include respond-min($tablet) {
			text-align: center;
			padding: 52px 0 10px;
			//}
			
		}		
	

	.quote-slider {
		
		figure, blockquote {
				display: inline-block;
				vertical-align: top;
				text-align: center;
			}

			figure {
				@include respond-max($tablet) {
					margin-right: 20px;
					max-width: 80px;
				}
			}

			blockquote p:before {
				content: open-quote;
			}

			blockquote p:after {
				content: close-quote;
			}

			img {
				margin: auto;
				border-radius: 108px;
				max-width: 100%;

				@include respond-min($tablet) {
					margin: initial;
				}
			}

			cite {
				display: block;
				font-size: 12px;
				font-family: 'Lato', sans-serif;
				font-style: italic;
				line-height: 1.2;

			strong {
				display: block;
				font-family: 'Open Sans', sans-serif;
				font-size: 14px;
				line-height: 2.0;
				font-style: normal;
		
				&:before {
					content: '-';
				}
			}
		}
		
			 figcaption {
				font-weight: bold;
				font-size: 11px;
				line-height: 32px;
			}

			 blockquote {
				max-width: 240px;
			    width: 66%;
				border: 0;
				color: #F07320;
				padding-left: 0;
				margin: auto;
				text-align: left;

				@include respond-min($tablet) {
					max-width: 440px;
					padding-left: 20px;
				}
			}

			blockquote p {
				font-weight: 400;
				font-size: 16px;
				line-height: 1.272727273;
				margin-bottom: 10px;


				@include respond-min($tablet) {
					font-size: 22px;
					padding-bottom: 15px;

				}
			}

			blockquote p strong {
				font-family: 'lato';
				font-weight: bold;
				font-size: 12px;
				line-height: 14px;
				color:#f07320;

			}

			 blockquote p:nth-child(3){
				font-family: 'lato';
				font-weight: italic;
				font-size: 10px;
				line-height: 14px;
				color:#f07320;
			}
		}
		
	// START OF OLD SECTION

	// 	background: #EFEFF0;
	// 	padding: 72px 0;
	// 	color: #0064a0;
	// .quote-slider {

		// @include respond-max($tablet) {
		// 	padding: 40px 0;
		// }

		

		//  h2 {
		// 	margin-bottom: 40px;
		// 	font-weight: 900;
		// 	font-size: 25px;
		// 	line-height: 1.19047619;
		// 	color:#0064a0;
		// 	text-align: center;
		// 	text-transform:uppercase;

		// }
	
	// 	li {
	// 		position: relative;
	// 		display: inline-block;
	// 		margin-right: -4px;
	// 		width: 100%;
	// 		margin-bottom: 20px;

	// 		@include respond-min($tablet) {
	// 			max-width: 470px;
	// 			margin-bottom: 0;
	// 		}

	// 		&:first-of-type {
	// 			margin-right: 0;

	// 			@include respond-min($tablet) {
	// 				margin-right: 20px;
	// 			}

	// 			&:after {
	// 				@include respond-min($tablet) {
	// 					content: '';
	// 					border: 1px solid #C1C1C1;
	// 					position: absolute;
	// 					right: 0;
	// 					top: 0;
	// 					bottom: 0;
	// 					transform: rotate(10deg);
	// 				}
	// 			}
	// 		}

	// 		&:last-of-type {
	// 			display: none;

	// 			@include respond-min($tablet) {
	// 				display: inline-block;
	// 			}
	// 		}

	// 		figure, blockquote {
	// 			display: inline-block;
	// 			vertical-align: top;
	// 			text-align: center;
	// 		}

	// 		figure {
	// 			@include respond-max($tablet) {
	// 				margin-right: 20px;
	// 				max-width: 80px;
	// 			}
	// 		}

	// 		blockquote p:before {
	// 			content: open-quote;
	// 		}

	// 		blockquote p:after {
	// 			content: close-quote;
	// 		}

	// 		img {
	// 			margin: auto;
	// 			border-radius: 108px;
	// 			max-width: 100%;

	// 			@include respond-min($tablet) {
	// 				margin: initial;
	// 			}
	// 		}
	// 	}

	// 	cite {
	// 		display: block;
	// 		font-size: 12px;
	// 		font-family: 'Lato', sans-serif;
	// 		font-style: italic;
	// 		line-height: 1.2;


	// 		strong {
	// 			display: block;
	// 			font-family: 'Open Sans', sans-serif;
	// 			font-size: 14px;
	// 			line-height: 2.0;
	// 			font-style: normal;
		
	// 			&:before {
	// 				content: '- ';
	// 			}
	// 		}
	// 	}
	
	// 	li figcaption {
	// 		font-weight: bold;
	// 		font-size: 11px;
	// 		line-height: 32px;
	// 	}

	// 	li blockquote {
	// 		max-width: 240px;
	// 		border: 0;
	// 		color: #F07320;
	// 		padding-left: 0;
	// 		margin: auto;
	// 		text-align: left;

	// 		@include respond-min($tablet) {
	// 			max-width: 340px;
	// 			padding-left: 20px;
	// 		}
	// 	}

	// 	blockquote p {
	// 		font-weight: 400;
	// 		font-size: 16px;
	// 		line-height: 1.272727273;
	// 		margin-bottom: 10px;


	// 		@include respond-min($tablet) {
	// 			font-size: 22px;
	// 			padding-bottom: 15px;

	// 		}
	// 	}

	// 	li blockquote p strong {
	// 		font-family: 'lato';
	// 		font-weight: bold;
	// 		font-size: 12px;
	// 		line-height: 14px;
	// 		color:#f07320;

	// 	}
	// 	li blockquote p:nth-child(3){
	// 		font-family: 'lato';
	// 		font-weight: italic;
	// 		font-size: 10px;
	// 		line-height: 14px;
	// 		color:#f07320;
	// 	}
	// }
//}

	/*end of quotes section*/

	/*long scroll section three*/

	.ls-section--three {
		overflow: hidden;

		@include respond-min($tablet) {
			//background: #251D1D url('../images/ls-sample.jpg') 0 0 no-repeat;
			//background-size: cover;
			background-color: #21455b;
		}
	
		h2 {
			margin-bottom: 20px;
			//padding-top: 40px;
			color: #4289B5;
			font-weight: 400;
			font-size: 32px;
			//text-align: center;
			text-align: center;

			@include respond-min($tablet) {
				text-align: right;
				font-size: 44px;
				color:#fff;
				//padding-top: 384px;
				padding-top: 60px;
				margin-right: 42px;
			}
		}

		.article-img {
			display: none;

			@include respond-min($tablet) {
				display: inherit;
				position: absolute;
				float: left;
				margin-left: -123px;
				margin-top: -100px;
			}
		}
		
		.article-list {
			max-width: 300px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			//float: right;

			@include respond-min($tablet) {
				max-width: none;
				width: auto;
				float: right;
			}
		}

		ul {
			text-align: center;

			
			@include respond-min($tablet) {
				margin-bottom: 60px;
				text-align: right;
			}
		}

		li {
			list-style-type: none;
			text-align: center;
			max-width: 460px;
			margin-right: -4px;
			margin-bottom: 40px;
			width: 100%;

			@include respond-min($tablet) {
				text-align: left;
				position: relative;

				#circle-one,
				#circle-two,
				#circle-three {
					&:before {
						border-left: 1px solid #80B0CF;
						-webkit-transform-origin: top center;
						-moz-transform-origin: top center;
						transform-origin: top center;
						position: absolute;
						content: '';
					}
				}

				#circle-one {
					position: absolute;
					left: -150px;
					top: 74px;
					width: 6px;
					height: 6px;
					background: #80B0CF;
					border-radius: 6px;

					&:before {
						height: 150px;
						top: 2px;
						right: 1px;
						-webkit-transform: rotate(-114deg);
						-ms-transform: rotate(-114deg);
						transform: rotate(-114deg);
					}
				}
				
				#circle-two {
					position: absolute;
					left: -124px;
					top: 43px;
					width: 6px;
					height: 6px;
					background: #80B0CF;
					border-radius: 6px;

					&:before {
						height: 120px;
						top: 2px;
						right: 0;
						-webkit-transform: rotate(-107deg);
						-ms-transform: rotate(-107deg);
						transform: rotate(-107deg);
					}
				}


				#circle-three {
					position: absolute;
					left: -112px;
					top: 0;
					width: 6px;
					height: 6px;
					background: #80B0CF;
					border-radius: 6px;
	
					&:before {
						height: 97px;
						top: 3px;
						right: 0;
						-webkit-transform: rotate(-85deg);
						-ms-transform: rotate(-85deg);
						transform: rotate(-85deg);
					}
				}

			}

			&:first-of-type {
				@include respond-min($tablet) {
					margin-right: 40px;
				}
			}
		}

		p {
			display: inline-block;
			margin-right: 10px;
			vertical-align: middle;

			@include respond-max($tablet - 1) {
				margin-bottom: 0;
				width: 100%;
			}

			.lt-ie9 & {
				width: auto;
				margin-bottom: 20px;
			}
		
			span {
				display: block;
			}
		}

		.title__category {
			margin-bottom: 16px;
			font-weight: 700;
			font-size: 14px;
			line-height: 1.583333333;
			color: #f07320;
			text-transform:uppercase;

			@include respond-min($tablet) {
				margin-bottom: 6px;
			}
		}

		.title__blog_post {
			max-width: 350px;
			font-size: 20px;
			line-height: 1.15;
			font-weight: 400;
			margin-bottom: 5px;
			color: #4289B5;
			
			@include respond-min($tablet) {
				color: #fff;
			}
		}

		.title__author {
			font-weight: 400;
			font-size: 14px;
			line-height: 1.5;
			color: #4289B5;
			
			@include respond-min($tablet) {
				color: #fff;
			}
		}

		.btn {
			vertical-align: middle;
			@include respond-min($tablet) {
				//margin-top: -17px;
				margin-top: 10px;
			}
		}
	}

	/*end of long scroll section three*/

	/*FAQ section*/

	.faq {
		display: none;
		padding: 62px 0;

		@include respond-min($tablet) {
			display: block;
		}
	}

	.faq h2 {
		margin-bottom: 38px;
		font-weight: 400;
		font-size: 30px;
		line-height: 1.066666667;
		color: #0064a0;
		text-align: center;
	}

	.wrap__tabs {
		list-style: none;
		margin: 0 auto;
		max-width: 740px;
	}

	.tab.isClosed {
		margin-bottom: 1px;

		.tab__title:before {
			content: '\f067';
		}

		.tab__body {
			margin: 0;
			max-height: 0;
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.tab.isOpen {
		.tab__title:before {
			content: '\f068';
		}

		.tab__body {
			max-height: 300px;
		}
	}

	.tab__title {
		position: relative;
	 	font-weight: 700;
	 	font-size: 20px;
	 	line-height: 1.333333333;
	 	color: #fff;
		cursor: pointer;
	 	padding: 12px 30px;
		background-color: #1a73a9;
		user-select: none;

		&:before {
			display: inline-block;
			font: normal normal normal 14px/1 'FontAwesome';
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			transform: translate(0, 0);
			position: absolute;
			right: 0;
			top: 0;
			border: 2px solid white;
			width: 25px; height: 25px;
			text-align: center;
			vertical-align: middle;
			border-radius: 20px;
			font-size: 16px;
			line-height: 1.4;
			bottom: 0;
			margin: auto 14px auto;
		}
	}

	 .tab__body {
		@include transition(max-height 0.4s ease-out, padding 0.4s ease-out);
	 	overflow: hidden;
	 	padding: 34px 30px 24px;
	 	font-size: 18px;
	 	line-height: 1.5;

	 	a {
	 		font-weight: 700;

	 	}
	 }

	/*End FAQ*/

	/*Unsurpassed tax section*/

	 .unsurpassed-tax {
	 	padding: 14.5px 0;
	 	background-color: #0064a0;

		
		h2 {
			position: relative;
			margin-top: 40px;
			margin-bottom: 40px;
			font-weight: 400;
			font-size: 28px;
			line-height: 1.2;
			text-align: center;
			color:#fff;

			a {
				// position: absolute;
				// top: 4px;
				margin-left: 25px;
				
			}
		}

		.btn {
			@include respond-max($tablet - 1) {
				max-width: 96px;
				display: block;
				margin: 20px auto 0;
			}
		}
	 }




	/*end of unsurpassed tax section*/

	/*page info section*/

	.page_info {
		background-color: #eee;
		// height:321.86px;
	}

	.page-info li {
		display: inline-block;
		text-align: center;
	}

	.page-info li p .title {
		font-weight: 900;
		font-size: 16px;
		line-height: 1.333333333;
		color:#0064a2;
		text-transform: uppercase;
	}

	.page-info li p {
		//font-weight: Semibold;
		font-size: 12.24px;
		line-height: 24px;
		color:#666;
		text-transform: uppercase;
	}

	.page-info li p li {
		font-weight:bold;
		font-size: 12.24px;
		line-height: 16px;
		color:#c1c1c1;
		text-transform: uppercase;
	}

	/*end of page info section*/

	/*footer section*/
	.page--footer {
		background-color: #eee;
		font-weight: bold;
		text-transform: uppercase;

		.title {
			margin-bottom: 25px;
			font-weight: 900;

			@include respond-min($tablet) {
				margin-bottom: 14px;
			}
			
			&, a {
				color: #0064a2;
				font-size: 13px;
				
				@include respond-min($tablet) {
					color: #0064a2;
				}
	
				&:hover {
					color: #c1c1c1;

					@include respond-min($tablet) {
						color: #666;
					}
				}
			}
		}

		a {
			color: #444;

			&:hover {
				color: #888;
			}
		}
	}

	.footer__site_map {
		overflow: hidden;


		@include respond-max($tablet - 1) {
			padding: 30px 0;
		}

		
	}

	.footer__section {
		list-style: none;
		display: inline-block;
		vertical-align: top;
		height: 100%;
		width: 100%;
		font-size: 13px;
		text-align: center;

			.show__tablet {
				font-size: 12px;
				font-weight: 500;
			}

		@include respond-min($tablet) {
			//position: relative;
			margin: 0 -4px -10000px 0;
			max-width: 240px;
			padding: 53px 28px 10000px 10px;
			border-right: 1px solid #dadada;
			text-align: left;
			//right: 6px;
		}

		&.footer__section--about {
			padding-left: 0;
		}

		&.footer__section--contact {
			padding-right: 0;
			border-right: 0;
		}

		li {
			overflow: hidden;
			font-weight: 600;
			margin-bottom: 4px;

			&:last-of-type {
				@include respond-min($tablet) {
					margin-bottom: 64px;
				}
			}

			.list__row {
				display: block;
				line-height: .8;
  				margin-top: 5px;
			}
			
			.list__sub_title {
				font-size: 13px;
				font-weight: 900;
			    float: left;
			    min-width: 83px;
			    color: #333;
			}

			.list__sub_body {
				color: #999;
				font-weight: 700;
				font-size: 12px;
			    a {
			    	color: #444;
			    }

			    a:hover {
			    	color: #666;
			    }

			    @include respond-min($tablet) {
			    	float: left;
			    }
			}
		}

	}

	
	.footer__section--products ,
	.footer__section--my_analyists,
	 {
		
		@include respond-min($tablet) {
		
			//max-width: 180px;
			
		}
	}



	.footer__section--contact {
		p {
			margin: 0;
			padding: 0;
		}
	}

	.share__social .ico {
		//transition: background-color .3s linear;
		display: inline-block;
		height: 28px;
		margin-right: 20px;
		width: 28px;
		//background: #666;
		font-size: 20px;
		line-height: 1.3;
		text-align: center;
		color: #444;

		&:last-of-type {
			margin-right: 0;
		}

		@include respond-min($tablet) {
			font-size: 16px;
			height: 18px;
			width: 18px;
			margin-right: 4px;
		}

		// &:hover {
		// 	background-color: #c1c1c1;
		// 	color: #fff;
		// }

		.fa {
			vertical-align: middle;
			color: #444;
		}
	}

	.footer__meta_data {
		padding: 13px;
		background-color: #666;
		color: #c1c1c1;
		font-size: 12px;
		text-align: left;

		a {
			color: #ececec;

			&:hover {
				background-color: #666;
			}
		}
	}

	/*button section*/

	.btn, .login-link a {
		@include transition(background 0.2s $ease-in-out-sine);
		font-weight: bold;
		font-size: 12px;
		line-height: 1.2;
		color:#fff;
		background-color:#F07320;
		padding: 8px 16px;
		text-transform: uppercase;
		text-decoration: none;

		&:hover {
			background-color: lighten(#F07320, 10%);
		}

		&:active {
			background-color: darken(#F07320, 10%);
		}
	}

	.btn-login {
		background: #0064A0;

		&:hover {
			background-color: lighten(#1A73A9, 10%);
		}

		&:active {
			background-color: darken(#1A73A9, 10%);
		}

		@include respond-min($tablet) {
			background-color:#F07320;

			&:hover {
				background-color: lighten(#F07320, 10%);
			}
		}
	}

	/*end of button section*/


	.show__mobile {
	    display: block;

	    @include respond-min($tablet) {
	        display: none !important;
	    }
	}

	.show__mobile_inline-block {
	    display: inline-block;

	    @include respond-min($tablet) {
	        display: none !important;
	    }
	}

	.show__tablet {
	    display: none;

	    @include respond-min($tablet) {
	        display: block;
	    }
	}

	.show__tablet_inline {
	    display: none;

	    @include respond-min($tablet) {
	        display: inline;
	    }
	}

	.show__tablet_inline_block {
	    display: none;

	    @include respond-min($tablet) {
	        display: inline-block;
	    }
	}

	.show__desktop {
	    display: none;

	    .oldie & {
	        display: block!important;
	    }

	    @include respond-min($desktop) {
	        display: block;
	    }
	}
}

.page--nonsub header .login-link a{display:inline-block;}

@keyframes showFromDisplayNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
  	display: block;
  	opacity: 0;
  }

  100% {
    opacity: 1;
  }
}