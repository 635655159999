// ------------------------------------------------------------------- //
//  Setup
// ------------------------------------------------------------------- //

$base-space: $base-line-height;
$base-ratio: $base-line-height / $base-font-size;

%vertical-rhythm {
  margin-bottom: 20px;
}

// ------------------------------------------------------------------- //
//  Base Typographic Rules
// ------------------------------------------------------------------- //

body {
  color: $base-font-color;
  font: #{$base-font-size} / #{$base-line-height} $base-font-family;
  -webkit-font-smoothing: antialiased;
}

// ------------------------------------------------------------------- //
//  Headings
//
//  The goal here is to define headings and establish practical
//  font-sizing from the get go.
//
//  Also includes a "double-stranded hierarchy" allowing you to use HTML
//  heading elements or the alternate extend included (based on the
//  military alphabet).
// ------------------------------------------------------------------- //

h1, h2, h3, h4, h5, h6 {
  font-family: $brand-font-family;
}

// h1,
// %alpha {
//   font-size: $h1-size;
//   font-weight: $normal;
//   line-height: 1;
// }

// h2,
// %bravo {
//   font-size: $h2-size;
//   font-weight: $light;
//   line-height: 1.4;
// }

// h3,
// %charlie {
//   font-size: $h3-size;
//   font-weight: $black;
//   line-height: 1.2;
//   letter-spacing: 1pt;
// }

// h4,
// %delta {
//   font-size: $h4-size;
// }

// h5,
// %echo {
//   font-size: $h5-size;
// }

// h6,
// %foxtrot {
//   font-size: $h6-size;
// }

// ------------------------------------------------------------------- //
//  Heading Groups
//
//  Target a group of headings stacked together (such as title/subtitle)
// ------------------------------------------------------------------- //

%hgroup {
  @extend %vertical-rhythm;

  @include headings(1,6) {
    margin-bottom: 0;
  }
}

// ------------------------------------------------------------------- //
//  Extra Large / Small Extends
// ------------------------------------------------------------------- //

%micro {
  font-size: $micro-size;
}

%milli {
  font-size: $milli-size;
}

%kilo {
  font-size: $kilo-size;
}

%mega {
  font-size: $mega-size;
}

%giga {
  font-size: $giga-size;
}


// ------------------------------------------------------------------- //
//  Links
// ------------------------------------------------------------------- //

a {
  color: $base-link-color;
  text-decoration: none;
  @include transition(color .3s linear);

  &:hover,
  &:active,
  &:focus {
    color: $hover-link-color;
  }
}
