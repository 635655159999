@mixin respond-min($width) {
    @if $desktop-width {
        .lt-ie9 & {
        	@content;
        }
    }
    @media screen and (min-width: $width) {
        @content;
    }
}
@mixin respond-max($width) {
    @if $desktop-width {
        .lt-ie9 & {
        	@content;
        }
    }
    @media screen and (max-width: $width) {
        @content;
    }
}

// Intrinsic Ratios Mixin
@mixin pad-ratio($x, $y, $selector: img) {
  position: relative;
  padding: 0 0 percentage($y/$x) 0;
  height: 0;
  #{$selector} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
