// -------------------------------------------------------------------- //
//  Product Page
// -------------------------------------------------------------------- //

.product__intro {
  margin-bottom: 60px;

  h1 {
    margin-bottom: 15px;
    color: $brand-color-2;
    font-weight: $normal;
  }

  p {
    margin-bottom: 10px;
  }
} // END product__intro


.product__options {
  margin-bottom: 60px;
} // END product__options


.product__tabs {
  margin-bottom: -1px;
} // END product__tabs


.product__tab {
  display: inline-block;
  padding: 5px 15px 6px;
  @include border(1px 1px 0, solid, transparent);
  color: #666;
  font-size: $milli-size;
  font-weight: $bold;
  text-transform: uppercase;

  &.active {
    background: #eee;
    @include border(1px 1px 0, solid, #ccc);
    color: $brand-color;
  }
} // END product__tab


.product__tab__content {
  padding: 30px;
  background: #eee;
  border: 1px solid #ccc;

  h2 {
    margin-bottom: 30px;
    font-weight: $normal;
    line-height: 1.2;
  }

  ul {
    margin-left: 1em;

    ul {
      margin-top: 5px;
    }
  }

  li {
    margin-bottom: 5px;
    list-style: none;

    &:before {
      content: "";
      float: left;
      display: block;
      @include size(4px);
      margin: 8px 0 0 -10px;
      background: url('../images/bullet.png');
    }
  }
}


.product-tab-expand {
  font-size: $milli-size;
  font-weight: $bold;
  text-transform: uppercase;

  &:after {
    color: $brand-color-2;
  }
}


.product__details {
  display: none;
  padding-top: 30px;
}


.product__more {
  margin-bottom: 30px;
  padding: 20px 45px;
  border: 5px solid $brand-color-2;
  border-radius: 5px;
  font-size: 18px;

  p {
    margin-bottom: 0;
    line-height: 1.5;
  }
}
