// ------------------------------------------------------------------- //
//  Error Messages / Modals
// ------------------------------------------------------------------- //

$success: #00B259;
$warning: #e9bc60;
$error: #d93600;


// 404
.page-customerror-404 {
  .block-system {
    @include outer-container;
    min-height: 500px;
    padding: 50px 0;

    h1 {
      margin-bottom: 10px;
    }
  }
}



// ------------------------------------------------------------------- //
//  Messages
// ------------------------------------------------------------------- //

.ta-message {
  z-index: 1200;
  position: fixed;
  top: 110px;
  left: 50%;
  width: 600px;
  margin: 0 !important;
  padding: 25px 45px 25px 25px !important;
  background: #f7f7f7;
  border: solid #666;
  border-width: 0 0 0 5px !important;
  font-weight: $semibold;
  box-shadow: 0 0 10px 5px rgba(0,0,0,0.1);
  transform: translateX(-50%);

  a {
    display: block;
  }

  &.ta-message--success {
    background: tint($success, 75%);
    border: solid $success;
  }

  &.ta-message--warning {
    background: tint($warning, 75%);
    border: solid $warning;
  }

  &.ta-message--error {
    background: tint($error, 75%);
    border: solid $error;
  }

  @media screen and (max-width: $mobile) {
    width: 90%;
  }
}

.ta-message__dismiss {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #666;
  cursor: pointer;

  &:before {
    font-size: 18px;
  }
}

// .ta-modal {
//   width: 600px;
//   margin-left: -300px;
//   padding: 20px;
//   background: #fff;
//   border: none;
//
//   &.success {
//     background: tint($success, 75%);
//     border-left: 3px solid $success;
//   }
//
//   &.warning {
//     background: tint($warning, 75%);
//     border-left: 3px solid $warning;
//   }
//
//   & + .ta-modal {
//     margin-top: -3px;
//   }
// } // END ta-modal
