// ------------------------------------------------------------------- //
//  Login Form
// ------------------------------------------------------------------- //

.form__header {
  @extend .landing__title;
  margin: 0;
} // END form__header

.login__form {
  position: relative;
  padding: 20px;
  @include border(0 1px 1px, solid, $base-border-color);
  @include clearfix;

  .description,
  .form-required {
    display: none;
  }

  .form-item-name,
  .form-item-pass {
    float: left;
    width: 220px;
    margin-right: 15px;
    
    input {
      margin-bottom: 0;
    }

    @media screen and (max-width: $mobile) {
      float: none;
      width: 100%;
      margin-right: 0;

      input {
        margin-bottom: 15px;
      }
    }
  }

  .form-actions {
    float: right;
    margin: 0;

    @media screen and (max-width: $mobile) {
      min-width: 40%;
    }
  }

  .form-submit {
    @extend .btn--secondary;
    width: 105px;
    margin-top: 19px;

    @media screen and (max-width: $mobile) {
      width: 100%;
      margin-top: 0;
    }
  }

  .form-item-persistent-login {
    position: absolute;
    right: 0;
    bottom: -13px;

    label {
      font-family: $brand-font-family;
      font-size: $micro-size;
      text-transform: uppercase;
    }

    .form-checkbox {
      float: right;
      display: block;
      margin: 3px 0 0 5px;
    }

    @media screen and (max-width: $mobile) {
      right: auto;
      bottom: 25px;
      left: 0;
    }
  }

  .forgot-password {
    position: absolute;
    bottom: -10px;
    left: 235px;
    color: $brand-color-3b;
    font-family: $brand-font-family;
    font-size: $micro-size;
    text-transform: uppercase;

    @media screen and (max-width: $mobile) {
      bottom: 0;
      left: 0;
    }
  }

  .item-list {
    margin: -20px 0 20px;
    line-height: 1.2;
    list-style: none;
  }
} // END login__form


.login__form__form,
.login__form--ip {
  @include clearfix;
  position: relative;
}

.login__form__form {
  padding-bottom: 20px;
}

.login__form--ip {
  height: 65px;

  @media screen and (max-width: $mobile) {
    height: auto;
  }
}

@media screen and (max-width: $mobile) {
  .login__content {
    margin-bottom: 30px;
    padding: 0;
  }
}




// -------------------------------------------------------------------- //
//  Forgot Password
// -------------------------------------------------------------------- //

.forgot-password {
  .form-item {
    float: left;
    width: 74%;
  }

  .form-actions {
    float: left;
    width: 20%;
  }

  .form-submit {
    width: auto;
  }
}





// -------------------------------------------------------------------- //
//  IP Login
// -------------------------------------------------------------------- //

.ip-already-registered,
.ip-register-here {
  font-size: 16px;
}

.ip-register-here {
  clear: both;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid $base-border-color;
}

.login__register-here-action {
  font-weight: 700;
}




// ------------------------------------------------------------------- //
//  Login Page CTAs
// ------------------------------------------------------------------- //

%login-cta-box {
  @include span-columns(6);
  margin-bottom: 50px;
  padding: 25px 20px;

  h1, p {
    margin-bottom: 20px;
  }

  a {
    margin-bottom: 0;
  }

  h1 {
    line-height: 1.1;
    text-transform: uppercase;
  }

  p {
    color: $brand-color-3b;
    font-size: $milli-size;
  }
} // END login-cta-box extend

.cta__module--register {
  @extend %login-cta-box;
  margin-top: 5px;
  @include border(2px 0 2px 2px, solid, $brand-color);

  h1 {
    color: $brand-color;
  }
} // END cta__module--register

.cta__module--subscribe {
  box-sizing: content-box;
  @extend %login-cta-box;
  width: 42%; // Override for content-box change
  margin-left: -1px; // Ensure slight overlap
  @include border(5px, solid, $brand-color-2);
  border-radius: 5px;

  h1 {
    color: $brand-color-2;
  }
} // END cta__module--subscribe





// ------------------------------------------------------------------- //
//  Login Sidebar
// ------------------------------------------------------------------- //

.contact-box {
  @include clearfix;

  dl {
    width: 270px;
    margin-bottom: 0;
    padding: 15px;
  }

  dd {
    float: left;
    width: 85px;
    font-family: $brand-font-family;
    font-size: $milli-size;
    font-weight: $bold;
    text-transform: uppercase;
  }

  dt {
    float: left;
    margin-bottom: 10px;
    color: $brand-color-3c;
  }
} // END login__sidebar

.other-links-box a {
  display: block;
  padding: 15px;
  border-bottom: 1px solid $base-border-color;
  font-size: $h2-size;
  font-weight: $bold;

  &:last-of-type {
    border-bottom: none;
  }
} // END other-links-box
