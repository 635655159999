// -------------------------------------------------------------------- //
//  Data Table Styles
// -------------------------------------------------------------------- //

.dataTables_filter {
  float: right;
  width: 30%;
  line-height: 30px;

  input {
    float: right;
    width: 75%;
  }
}

.dataTables_wrapper {
  // Align table properly and put "Searching" inline with title
  margin-top: -50px;

  table {
    clear: both; // Ensure table clears filter
    width: 100%;
    border: 1px solid $base-border-color;
  }

  td {
    vertical-align: top;
  }

  thead {
    background: #eee;
    font-size: $milli-size;
    line-height: 1;
    text-transform: uppercase;

    .select-all {
      text-align: center;

      input {
        margin: 0;
      }
    }

    th {
      padding: 10px;
      border-bottom: 2px solid $base-border-color;
    }
  }

  .sorting:after,
  .sorting_asc:after,
  .sorting_desc:after {
    content: "";
    float: right;
    display: block;
    width: 12px;
    height: 12px;
  }

  .sorting_asc:after {
    background: url("../images/table-sorting.png") no-repeat 0 -24px;
  }

  .sorting:after {
    background: url("../images/table-sorting.png") no-repeat 0 -12px;
  }

  .sorting_desc:after {
    background: url("../images/table-sorting.png") no-repeat 0 0;
  }

  tr.odd {
    padding: 0;
    background: #fff;
  }
  tr.even {
    padding: 0;
    background: #f9f9f9;
  }

  tbody {
    td {
      padding: 5px 10px;
    }

    // Select Box
    td:nth-child(1) {
      input {
        margin: 3px 0 0 0;
      }
    }
  } // END tbody

  .form-submit {
    margin-bottom: 60px;
  }

  .save-tags {
    float: right;
  }
}


// Favorite Specific Sizing
#dataTableFavorites {
  // Title
  td:nth-child(2) {
    width: 30%;
  }

  // Author
  td:nth-child(3) {
    width: 20%;
  }

  // Publication Date
  td:nth-child(4) {
    width: 20%;
  }

  // New tag
  td:nth-child(5) {
    input {
      margin-bottom: 5px;
    }

    .description {
      color: $brand-color-3c;
      font-size: $mini-size !important;
      font-style: italic;
    }
  } // END td:nth-child(5)

  // Existing tags
  td:nth-child(6) {
    width: 15%;

    ul {
      list-style: none;
    }

    li {
      display: inline-block;
      margin: 0 5px 5px 0;
      padding: 5px 10px;
      background: #f0f0f0;
      font-size: $mini-size;
      line-height: 14px;
      transition: background .3s;

      .remove-tag:before {
        margin-right: 0;
        margin-left: 5px;
        color: $brand-color-3c;
        opacity: .5;
        transition: all .3s;

        &:hover {
          opacity: 1;
        }
      }

      &:hover {
        background: darken(#f0f0f0, 5%);
      }
    }
  }
}



// State Tax Amnesty Programs
.page-state-tax-amnesty-programs {
  h2.title {
    margin-bottom: 0 !important;
  }

  .dataTables_wrapper {
    margin-top: 0;
  }
}


#dataTableStateTaxPrograms {
  margin-bottom: 60px;
  font-size: 12px;

  thead th {
    padding: 10px 5px;

    // Force hide sorting
    &:after {
      display: none;
    }
  }
}
