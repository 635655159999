// ------------------------------------------------------------------- //
//  Dailies
// ------------------------------------------------------------------- //

.dailies__sub-category{
  display: inline-block;
  margin: 20px 0 0;
  padding: 5px 10px;
  background: $brand-color-3e;
  font-family: $brand-font-family;
  font-size: $milli-size;
  line-height: 1;

  // Reduce media__meta spacing when sub-cat is present
  // Sub-categorys exist as spans prior to the media object
  & + .media .media__content{
    padding-top: 10px;
  }
} // END dailies__sub-category


// ------------------------------------------------------------------- //
//  Latest News

.dailies__latest-news {
  background: #ecebeb;

  .content-block__header {
    border-bottom-color: $brand-color-2;
  }

  .content-block__title {
    color: $brand-color-2;
  }

  .media {
    border-bottom-color: #ccc;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  .download-full-text {
    display: block;
    margin-top: 20px;
    color: $brand-color-3b;
    font-family: $brand-font-family;
    font-size: $mini-size;
    font-weight: $black;
    text-transform: uppercase;

    &:hover {
      color: $brand-color-3a;
    }
  }
}


// ------------------------------------------------------------------- //
//  Daily Title Tweak
//  
//  Prevent overlapping with Reading Queue button

.dailies__content .media__title {
  margin-right: 50px;

  .not-logged-in & {
    margin-right: 0;
  }
}
