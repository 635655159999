@font-face {
  font-family: 'tax';
  src: url('../fonts/tax.eot?54690711');
  src: url('../fonts/tax.eot?54690711#iefix') format('embedded-opentype'),
       url('../fonts/tax.woff?54690711') format('woff'),
       url('../fonts/tax.ttf?54690711') format('truetype'),
       url('../fonts/tax.svg?54690711#tax') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icon,
[class^="icon-"]:before,
[class*="icon-"]:before {
  font-family: "tax";
  font-size: $base-font-size;
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: auto;
  margin-right: 10px;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 14px;
}

%icon-after,
[class*="--after"]:after {
  font-family: "tax";
  font-size: $base-font-size;
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: auto;
  margin-left: 10px;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
}

.icon-only:before,
.icon-only:after {
  margin: 0;
  text-align: center;
}

.no-icon {
  &:after,
  &:before {
    display: none;
  }
}

.icon-down-dir:before { content: '\61'; } /* 'a' */
.icon-up-dir:before { content: '\62'; } /* 'b' */
.icon-left-dir:before { content: '\63'; } /* 'c' */
.icon-right-dir:before { content: '\64'; } /* 'd' */
.icon-facebook:before { content: '\65'; } /* 'e' */
.icon-twitter:before { content: '\66'; } /* 'f' */
.icon-gplus:before { content: '\67'; } /* 'g' */
.icon-linkedin:before { content: '\68'; } /* 'h' */
.icon-star:before { content: '\69'; } /* 'i' */
.icon-star-empty:before { content: '\6a'; } /* 'j' */
.icon-plus-circled:before { content: '\6b'; } /* 'k' */
.icon-info-circled:before { content: '\6d'; } /* 'm' */
.icon-print:before { content: '\6e'; } /* 'n' */
.icon-comment:before { content: '\6f'; } /* 'o' */
.icon-chat:before { content: '\70'; } /* 'p' */
.icon-search:before { content: '\71'; } /* 'q' */
.icon-pdf:before { content: '\72'; } /* 'r' */
.icon-resize-vertical:before { content: '\73'; } /* 's' */
.icon-cancel:before { content: '\74'; } /* 't' */
.icon-cancel-circled:before { content: '\75'; } /* 'u' */
.icon-help-circled:before { content: '\76'; } /* 'v' */
.icon-equalizer:before { content: '\77'; } /* 'w' */
.icon-building:before { content: '\78'; } /* 'x' */
.icon-mail:before { content: '\79'; } /* 'y' */
.icon-menu:before { content: '\7a'; } /* 'z' */

// After Versions
.icon-down-dir--after:after { content: '\61'; } /* 'a' */
.icon-up-dir--after:after { content: '\62'; } /* 'b' */
.icon-left-dir--after:after { content: '\63'; } /* 'c' */
.icon-right-dir--after:after { content: '\64'; } /* 'd' */
.icon-facebook--after:after { content: '\65'; } /* 'e' */
.icon-twitter--after:after { content: '\66'; } /* 'f' */
.icon-gplus--after:after { content: '\67'; } /* 'g' */
.icon-linkedin--after:after { content: '\68'; } /* 'h' */
.icon-star--after:after { content: '\69'; } /* 'i' */
.icon-star-empty--after:after { content: '\6a'; } /* 'j' */
.icon-plus-circled--after:after { content: '\6b'; } /* 'k' */
.icon-info-circled--after:after { content: '\6d'; } /* 'm' */
.icon-print--after:after { content: '\6e'; } /* 'n' */
.icon-comment--after:after { content: '\6f'; } /* 'o' */
.icon-chat--after:after { content: '\70'; } /* 'p' */
.icon-search--after:after { content: '\71'; } /* 'q' */
.icon-pdf--after:after { content: '\72'; } /* 'r' */
.icon-resize-vertical--after:after { content: '\73'; } /* 's' */
.icon-cancel--after:after { content: '\74'; } /* 't' */
.icon-cancel-circled--after:after { content: '\75'; } /* 'u' */
.icon-help-circled--after:after { content: '\76'; } /* 'v' */
.icon-equalizer--after:after { content: '\77'; } /* 'w' */
.icon-building--after:after { content: '\78'; } /* 'x' */
.icon-mail--after:after { content: '\79'; } /* 'y' */
.icon-menu--after:after { content: '\7a'; } /* 'z' */
