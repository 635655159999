// ------------------------------------------------------------------- //
//  Grid Settings
// ------------------------------------------------------------------- //

$max-width: 960px !default;
$grid-columns: 12 !default;
$grid-gutter: 15px !default;
$column: $max-width / $grid-columns !default;

// ------------------------------------------------------------------- //
//  Set Container
// ------------------------------------------------------------------- //

@mixin outer-container {
  max-width: $max-width;
  width: 100%;
  min-width: $max-width;
  margin: 0 auto;

  @media screen and (max-width: $tablet) {
    min-width: 0px;
  }
}

// ------------------------------------------------------------------- //
//  Set Rows
// ------------------------------------------------------------------- //

@mixin row {
  @include clearfix;
  position: relative;
  display: block;
  margin: 0 -20px;
}

// ------------------------------------------------------------------- //
//  Set Widths
// ------------------------------------------------------------------- //

@mixin span-columns($col-size, $flow: left) {

  $col-width: percentage($col-size / $grid-columns);

  @if $col-size > $grid-columns {
    @warn "Column size should not be greater than max-columns (#{$grid-columns}).";
  }

  @else {
    float: left;
    width: $col-width;
    padding: 0 $grid-gutter;

    @if $flow == "right" {
      float: right;
    }

    @else if $flow == "center" {
      float: none;
      margin: 0 auto;
    }
  }
}

// ------------------------------------------------------------------- //
//  Shift
// ------------------------------------------------------------------- //

@mixin shift($col-shift) {
  $shift: percentage($col-shift / 12);

  @if $col-shift > 12 {
    @warn "Should not shift greater than max-columns (12).";
  }

  @else {
    margin-left: $shift;
  }
}

// ------------------------------------------------------------------- //
//  Hug
// ------------------------------------------------------------------- //

@mixin hug($direction) {

  @if $direction == "left" {
    padding-left: 0;
  }

  @else if $direction == "right" {
    padding-right: 0;
  }

  @else if $direction == "both" {
    padding: 0;
  }

  @else {
    @warn "Hug requires 'left', 'right', or 'both'. Please choose a valid direction.";
  }
}


// -------------------------------------------------------------------- //
//  Media Queries
// -------------------------------------------------------------------- //

@mixin screen($res-min, $res-max, $orientation: false) {
  @if $orientation {
    @media screen and (min-width: $res-min) and (max-width: $res-max)
    and (orientation:#{$orientation}) {
      @content;
    }
  }
  @else {
    @media screen and (min-width: $res-min) and (max-width: $res-max) {
      @content;
    }
  }
}

@mixin max-screen($res) {
  @media screen and (max-width: $res) {
    @content;
  }
}

@mixin min-screen($res) {
  @media screen and (min-width: $res) {
    @content;
  }
}
