// ------------------------------------------------------------------- //
//  Ad Spaces
// ------------------------------------------------------------------- //

.ad {
  position: relative;
  width: 460px;
  height: 255px;
  padding: 20px;
  margin-bottom: 20px;

  img {
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: $mobile) {
      width: 100%;
      height: auto;
    }
  }

  .youtube-link {
    position: absolute;
    right: 30px;
    bottom: 30px;
    display: block;
    width: 98px;
    height: 71px;
    background-image: url("../images/youtube-logo-white.png");
  }

  @media screen and (max-width: $mobile) {
    width: 100%;
    height: auto;
    margin-bottom: 60px;
    padding: 10px;
  }

  @include screen($mobile, $tablet) {
    width: 100%;
    overflow: hidden;
  }
}

.ad__title {
  position: relative;
  color: #fff;
  line-height: 1.2;

  // Slightly reduce font-size on ad title for single line title
  .ie & {
    font-size: 26px;
  }

  @media screen and (max-width: $mobile) {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.ad__content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 140px;

  p {
    color: #fff;
    line-height: 1.2;
  }

  @media screen and (max-width: $mobile) {
    position: relative;
    bottom: auto;
    left: auto;
    width: 90px;

    .btn--primary {
      width: 110px;
      height: 32px;
      padding: 10px;
    }
  }
}


// -------------------------------------------------------------------- //
//  Help Videos

.help-video-block {
  margin-bottom: 20px;
  padding: 15px 20px;
  background: #e2eef5;
}

.help-video-title {
  color: $base-font-color;
  margin-bottom: 5px;
}

.help-video-text {
  margin-bottom: 5px;
  font-size: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.help-video-or {
  margin: 5px 0;
  font-size: 10px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}
