// ------------------------------------------------------------------- //
//  Treaties
// ------------------------------------------------------------------- //

.treaties__content--sidebar {
  .tooltip {
    color: #fff;
  }

  .content-block {
    .content-block__header {
      border-bottom-color: $brand-color-3c;
    }

    .content-block__title {
      color: $brand-color-3c;
    }
  }
}

// Search / Quick Locate
.treaties-search,
.treaties-quick-find {
  padding: 10px;

  .chosen-container {
    width: 100% !important;
  }

  input[type="text"] {
    background: #fff;
  }
} // END search/quick-find


.treaties-search {
  .form-item:first-child {
    float: left;
    width: 204px;

    input {
      border-right: 0;
    }
  }

  .form-submit {
    @include removeAppearance;
    @include hide-text;
    float: right;
    width: 30px;
    height: 30px;
    background: url("../images/ta-submit-gray.png") no-repeat 0 0;

    &:hover {
      background-position: 0 -30px;
    }
  }

  .chosen-container {
    margin-bottom: 0;
  }
} // END treaties-search


.treaties-quick-find {
  // Set As Default / Locate / Clear Defaults
  .btn--primary,
  .btn--tertiary {
    width: 100%;
    text-align: center;
  }

  // Set As Default
  #edit-save {
    margin: -5px 0 10px;
  }

  // Locate
  [id*="edit-submit"] {
    margin-bottom: 5px;
  }

  // After default is set, confirmation text
  .default-confirmation {
    margin-bottom: 10px;
    color: $brand-color-3c;
    font-size: $milli-size;
    text-align: center;
    text-transform: uppercase;
  }

  .taTip-treaties {
    color: #fff;
  }
} // END treaties-quick-find


#filter-treaties {
  margin-top: 15px;
}


#compare-form-wrapper {
  @include clearfix;
  margin-bottom: 20px;
}


// Compare Actions (Shared across Quick Rates, Treaty and Treaty Articles)
.compare__action {
  clear: both;

  .compare,
  .compare-reset {
    float: right;
  }

  .compare {
    margin-left: 15px;
  }

  .compare-reset {
    @include removeAppearance;
    color: $brand-color-3c;
    font-size: $micro-size;
    font-weight: $normal;
    line-height: 30px;
  }
}

@media screen and (max-width: $mobile) {
  .treaties .contain {
    display: flex;
    flex-direction: column-reverse;
  }
}





// -------------------------------------------------------------------- //
//  Quick Treaty Locator Results
// -------------------------------------------------------------------- //

[class*="quick-treaties"] .treaty {
  display: block;
  margin: 10px 0;
}





// ------------------------------------------------------------------- //
//  Treaties - Compare (Landing)
// ------------------------------------------------------------------- //

.treaties-compare__add {
  @include clearfix;
  margin-bottom: 15px;

  .form-element {
    float: left;
    width: 250px;
    margin-right: 15px;

    .chosen-container {
      width: 100% !important;
    }
  } // ENd form-element

  .add-partners {
    float: right;
    width: 55px;
    margin-top: 25px;
    text-align: center;
  }
} // END treaties-compare__add

#treaties-compare-form,
#treaties-article-compare-form {
  .form-item-p1,
  .form-item-p2,
  .form-item-p3,
  .form-item-p4 {
    float: left;
    width: 255px;
    margin-right: 10px;
  }

  [id*="edit-search-btn"] {
    float: right;
    width: 70px;
    margin-top: 19px; // Align with select boxes
  }

  // Results
  .form-radios {
    clear: both;
    background: #fff;

    .form-item {
      position: relative;
      margin: 0;
      padding: 5px 10px;
      border: 1px solid $base-border-color;
      line-height: 1.2;
      cursor: pointer;

      + .form-item {
        border-top: 0;
      }

      .form-radio {
        float: left;
        margin: 0 10px 0 0;

        + label {
          display: block;
          overflow: hidden;
          line-height: 1.2;
          margin-bottom: 0;
          text-transform: none;
          cursor: pointer;
        }
      }

      &:last-child {
        margin-bottom: 30px;
      }
    } // END form-item
  } // END form-radios
} // END treaties-article-compare-form

// ------------------------------------------------------------------- //
//  Treaties - Compare (Results)

.page-worldwide-tax-treaties-treaties-compare-results {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 70px;
    padding: 0 20px;
    background: #efefef;
  }

  .treaties-compare__title {
    display: inline-block;
    height: 70px;
    margin-left: 10px;
    color: #666;
    font-size: 28px;
    font-weight: 400;
    line-height: 84px;
  }
}

.treaties-compare__left,
.treaties-compare__right {
  position: fixed;
  top: 70px;
  width: 50%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  header {
    position: fixed;
    top: 70px;
    width: 50%;
    padding: 20px;
    background: #fff;
    box-shadow: 0 5px 5px 0px rgba(0,0,0,0.1);
  }

  hgroup {
    margin-bottom: 0;
  }

  .article__title {
    font-size: 20px;
    font-weight: 600;
  }

  .article__bar {
    margin-bottom: 0;
  }

  .article__ta-full-text {
    padding: 0 20px;
  }
}

.treaties-compare__right {
  left: 50%;
  border-left: 1px solid $base-border-color;
}


.treaty-metadata {
  margin: 20px;
  padding: 10px 20px;
  background: #eee;
}

.treaty-metadata__row {
  @include clearfix;
  display: block;
  padding: 5px 0;
  border-bottom: 1px solid $base-border-color;

  &:last-child {
    border-bottom: 0;
  }
}

.treaty-metadata__label,
.treaty-metadata__results {
  @include span-columns(6);
}

.treaty-metadata__label {
  float: left;
  width: 30%;  
  color: #777;
  font-size: $milli-size;
  text-transform: uppercase;
}

.treaty-metadata__results {
  float: left;
  width: 70%;
  font-size: $milli-size;

   a{
    color: $brand-color;
   }
}




// -------------------------------------------------------------------- //
//  Compare Rates
// -------------------------------------------------------------------- //

.rates-compare {
  @include clearfix;

  // Partner 1 and 2 selection
  .form-item-partner1,
  .form-item-partner2 {
    float: left;
    width: 245px;
    margin-right: 10px;
  }

  // Add to compare table
  [id*="edit-add"] {
    @extend .btn--secondary;
    float: right;
    width: 65px;
    margin: 24px 0 20px; // Align with select boxes
  }

  // Selected Comparisons
  .form-wrapper[id*="edit-selected--"] {
    display: block;
    clear: both;
    margin-bottom: 10px;
    padding: 0;
    background: #fff;
    border: 1px solid $base-border-color;

    // Single compare line
    .inline-elements {
      position: relative;
      padding: 10px;
      border-bottom: 1px solid $base-border-color;

      // Comparison text
      .form-item {
        margin-bottom: 0;
      }

      // Comparison remove
      .remove-button {
        @include position(absolute, 5px 5px 0 0);
        opacity: 0;
        transition: opacity, .3s;
      }

      &:last-child {
        border-bottom: none;
      }

      &:hover .remove-button {
        opacity: 1;
      }
    } // END inline-elements
  } // END edit-selected
} // END treatiest-compare





// -------------------------------------------------------------------- //
//  Quick Rates
// -------------------------------------------------------------------- //

.quick-rates-table {
  width: 100%;

  th {
    background: #f3f3f3;
  }

  tr:nth-child(even) {
    background: #eee;
  }
} // END quick-rates-table





// -------------------------------------------------------------------- //
//  Treaties Doc ToC
// -------------------------------------------------------------------- //

.node-type-treaties .article__content--sidebar .block-tafieldrules {
  .header{
    display: none;
  }

  ul {
    font-family: $brand-font-family;
    list-style: none;

    a{
      display: block;
      width: 100%;
      padding: 5px 10px;
      border-bottom: 1px solid $base-border-color;
      color: $brand-color-3b;
      font-size: $micro-size;
      font-weight: $bold;
      text-transform: uppercase;
      transition: all .3s;

      &:hover,
      &.current {
        background: $brand-color-3e;
        color: $brand-color-2;
      }
    } // END ToC links

    &:before{
      content: "Table of Contents";
      display: block;
      width: 100%;
      min-height: 20px;
      padding: 5px 0;
      border-bottom: 3px solid #ddd;
      color: $brand-color-3c;
      font-family: $brand-font-family;
      font-size: $tiny-size;
      font-weight: $black;
      line-height: 1;
      text-transform: uppercase;
    }
  } // END ToC list
} // END node-type-treaties





// -------------------------------------------------------------------- //
//  Treaties News
// -------------------------------------------------------------------- //

.treaties-news{

  .form-type-select{
    &:before{
      content: "OR";
      display: block;
      margin: 10px 0;
      padding: 2px 0;
      background: #eee;
      color: $brand-color-3b;
      font-size: $milli-size;
      font-weight: $black;
      text-align: center;
      box-shadow: inset 0 2px 0 rgba(0,0,0,0.05);
    }

    &:first-of-type:before{
      display: none;
    }
  }

  .reset-form{
    float: right;
  }
} // END treaties-news
