// -------------------------------------------------------------------- //
//  Media Page
// -------------------------------------------------------------------- //

.taMedia {
  @include clearfix;
}

.taMedia__press-releases,
.taMedia__mentions {
  @include span-columns(6);
  margin-bottom: 35px;
  padding-top: 20px;
}

.taMedia__press-releases {
  border: solid $base-border-color;
  border-width: 0 0 1px 1px;
}

.taMedia__mentions {
  background: #eee;
  border: solid #eee;
  border-width: 0 1px 1px 0;

  .media {
    border-bottom-color: #ddd;
  }
}
