// ------------------------------------------------------------------- //
//  Footer
// ------------------------------------------------------------------- //

.sticky-footer {
  @include clearfix;
  display: block;
  height: 310px;
}

.footer__main {
  height: 280px;
  background: $brand-color-3e;
  font-family: $brand-font-family;
  font-size: $milli-size;
  text-transform: uppercase;

  @media screen and (max-width: $mobile) {
    height: auto;

    .contain {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
}

.footer__nav-list {
  @include span-columns(3);
  height: 280px;
  padding: 50px 20px 0;
  border-right: 1px solid #dadada;

  &:first-child {
    @include hug(left);

    @media screen and (max-width: $mobile) {
      padding-left: 20px;
    }

    @include screen($mobile, $tablet) {
      padding-left: 20px;
    }
  }

  &:last-child {
    float: right;
    @include hug(right);
    border-right: none;

    @include screen($mobile, $tablet) {
      width: 230px;
      padding-right: 20px;
    }
  }

  .nav-list__title {
    margin-bottom: 20px;
    color: $brand-color;
    font-weight: 900;
    letter-spacing: 0;

    @media screen and (max-width: $mobile) {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  ul {
    margin-bottom: 0;
    list-style: none;
  }

  a {
    color: $brand-color-3a;
  }

  @media screen and (max-width: $mobile) {
    float: none;
    // flex: 1 0 100%;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 15px 20px;
    border-top: 1px solid $base-border-color;

    &:first-child {
      border-top-width: 0;
    }
  }

  @include screen($mobile, $tablet) {
    width: 175px;
    padding: 20px;
  }
} // END footer__nav-list

.footer__contact {
  dd {
    clear: right;
    @include span-columns(4);
    @include hug(left);
    font-size: $h3-size;
    font-weight: $black;
    line-height: 1;
    overflow: hidden;

    .ie8 & {
      width: 40%;
    }
  }

  dt {
    @include span-columns(8, right);
    @include hug(right);
    padding-left: 10px;
    margin-bottom: 15px;
    font-weight: $bold;
    line-height: 1;
    color: $brand-color-3c;

    .ie8 & {
      width: 60%;
    }
  }
} // END footer__contact

.footer__social-row {
  a:before {
    font-size: 16px;
    color: $brand-color-3b;
  }
}

.footer__bar {
  height: 30px;
  padding: 10px 0;
  background: $brand-color-3b;
  font-size: $mini-size;
  font-weight: $bold;
  line-height: 1;
  text-transform: uppercase;
  color: $brand-color-3c;

  a {
    color: $brand-color-3d;

    &:hover {
      color: #aaa;
    }
  }

  @media screen and (max-width: $mobile) {
    height: auto;
    padding: 10px 15px;
    line-height: 1.2;
    text-align: center;
  }
} // END footer__bar
