// ------------------------------------------------------------------- //
//  Shared Styles
// ------------------------------------------------------------------- //

hr {
  @extend %vertical-rhythm;
  @include border(1px 0 0 0, solid, $base-border-color);
}

blockquote {
  padding-left: 20px;
  border-left: 5px solid $brand-color-3d;
  font-size: $h2-size;
  color: $brand-color-3b;
  line-height: 1.6;
}

.content__action-bar {
  @extend %vertical-rhythm;
  display: inline-block;
  border: 1px solid $base-border-color;
}

.action-bar__section {
  display: inline-block;
  padding: 10px;
  border-left: 1px solid $base-border-color;

  a {
    color: $brand-color-3d;
  }

  &:nth-child(1) {
    border: none;
  }
} // END action-bar__section


// ------------------------------------------------------------------- //
//  Basic Layout
// ------------------------------------------------------------------- //

.no-scroll {
  height: 100% !important;
  overflow: hidden;
}

#content-wrap {
  overflow-x: hidden;
}

// When in admin view, move mega menu down to be visible
.logged-in.toolbar-drawer header .visible {
  top: 65px;
}

.contain {
  position: relative;
  @include outer-container;
  @include clearfix;
}

.sidebar-right {
  min-height: 100%;

  .contain > section:nth-child(1) {
    float: left;
    width: 645px;

    @include screen($mobile, $tablet) {
      width: 65%;
    }
  }
  .contain > section:nth-child(2) {
    float: right;
    width: 256px;

    .media__image,
    .media__image img {
      width: 55px;
      height: 55px;
    }

    @include screen($mobile, $tablet) {
      width: 30%;
    }
  }

  .ie8 & {
    .contain > section:first-child {
      float: left;
      width: 645px;

      .media__image,
      .media__image img {
        width: 55px;
        height: 55px;
      }

      + section {
        float: right;
        width: 256px;
      }
    }
  }

  @media screen and (max-width: $mobile){
    .contain > section:nth-child(1),
    .contain > section:nth-child(2){
      @include span-columns(12);
    }
  }

  @media screen and (min-width: $mobile) and (max-width: $tablet) {
    padding: 20px;
  }
} // END sidebar-right

.sidebar-left {
  min-height: 100%;

  .contain > section:nth-child(1) {
    float: left;
    width: 256px;

    .media__image,
    .media__image img {
      width: 55px;
      height: 55px;
    }

    @include screen($mobile, $tablet) {
      width: 30%;
    }
  }
  .contain > section:nth-child(2) {
    float: right;
    width: 645px;

    @include screen($mobile, $tablet) {
      width: 65%;
    }
  }

  .ie8 & {
    .contain > section:first-child {
      float: left;
      width: 256px;

      .media__image,
      .media__image img {
        width: 55px;
        height: 55px;
      }

      + section {
        float: right;
        width: 645px;
      }
    }
  }

  @media screen and (max-width: $mobile){
    .contain > section:nth-child(1),
    .contain > section:nth-child(2){
      @include span-columns(12);
    }
  }

  @media screen and (min-width: $mobile) and (max-width: $tablet) {
    padding: 20px;
  }
} // END sidebar-left

.split {
  min-height: 100%;

  .contain > section:nth-child(1) {
    @include span-columns(6);
    @include hug(left);
  }
  .contain > section:nth-child(2) {
    @include span-columns(6);
    @include hug(right);
  }

  .ie8 & {
    .contain > section {
      @include span-columns(6);
      
      &:first-child {
        padding-left: 0;
      }

      + section {
        padding-right: 0;
      }
    }
  }

  @media screen and (max-width: $mobile){
    .contain > section:nth-child(1),
    .contain > section:nth-child(2){
      @include span-columns(12);
    }
  }

  @media screen and (min-width: $mobile) and (max-width: $tablet) {
    padding: 20px;
  }
} // END split
