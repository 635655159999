// ------------------------------------------------------------------- //
//  Buttons
// ------------------------------------------------------------------- //

%btn {
  -webkit-appearance: none;
  appearance: none;
  display: inline-block;
  height: 30px;
  padding: 10px;
  border: solid black;
  border-width: 0 0 1px 0;
  color: #fff;
  font-family: $brand-font-family;
  font-size: $mini-size;
  font-weight: $black;
  line-height: 1;
  text-transform: uppercase;
  transition: all .3s;

  &:hover {
    color: #fff;
  }

  &:after {
    @extend %icon-after;
    float: right;
    display: block;
    content: "\64";
    font-size: $milli-size !important;
    color: #fff !important;
  }

  @media screen and (max-width: $mobile) {
    height: 40px;
    padding: 15px 10px;
  }
} // END %btn

.btn--large {
  font-size: $base-font-size;
}

// Primary
.btn--primary {
  @extend %btn;
  background: $brand-color-2;
  border-color: $brand-color-2--dark;

  &:hover {
    background: $brand-color-2--dark;
  }
}

// Secondary
.btn--secondary {
  @extend %btn;
  background: $brand-color;
  border-color: $brand-color--dark;

  &:hover {
    background: $brand-color--dark;
  }
}

// Tertiary
.btn--tertiary {
  @extend %btn;
  background: #aaa;
  border-color: #888;

  &:hover {
    background: #888;
  }
}


// Text Links
.text-link {
  color: $brand-color-2;
  font-size: $milli-size;
  font-weight: $black;
  text-transform: uppercase;

  &:hover {
    color: $brand-color-2--dark;
  }
}


// Icon Only
.icon-only:before {
  padding: 0;
}
