// ------------------------------------------------------------------- //
//  Media Containers
// ------------------------------------------------------------------- //

.content-block,
[class*="content-block--"] {
  margin-bottom: 50px;

  .chosen-container {
    width: 100% !important;
  }
} // END content-block/--*

.link-list > a {
  display: block;
  padding: 3px 0;
  border-bottom: 1px solid $base-border-color;
  font-size: $milli-size;
} // END link-list

.link-list--sub-category a {
  display: block;
  padding: 3px 0 3px 15px;
  border-bottom: 1px solid $base-border-color;
  font-size: $milli-size;
}


// ------------------------------------------------------------------- //
//  Media Containers - Default Styles
// ------------------------------------------------------------------- //

.content-block__header {
  display: table;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 3px solid $brand-color;
} // END content-block__header

.content-block__title,
.content-block__link {
  display: table-cell;
  vertical-align: bottom;
}

.content-block__title {
  margin-bottom: 0;
  color: $brand-color;
  font-family: $brand-font-family;
  font-size: $tiny-size;
  font-weight: $black;
  line-height: 1;
  letter-spacing: 1pt;
  text-transform: uppercase;
} // END content-block__title

.content-block__link {
  font-family: $brand-font-family;
  font-size: $micro-size;
  font-weight: $black;
  line-height: 1;
  text-align: right;
  text-transform: uppercase;
  color: $brand-color-2;
  cursor: pointer;

  &:hover {
    color: $brand-color-2--dark;
  }
} // END content-block__link


// ------------------------------------------------------------------- //
//  Media Containers - Boxed
// ------------------------------------------------------------------- //

.content-block--box {
  border: 1px solid $brand-color-3d;

  .content-block__header {
    padding: 10px 20px;
    background: $brand-color-3e;
    border-bottom: none;
  }

  .content-block__title {
    color: $brand-color-3b;
  } // END content-block__title

  .media__content {
    padding: 20px;
  }

  .media__title {
    color: $brand-color;
    font-size: $h2-size;
    font-weight: $bold;

    a {
      color: $brand-color;

      &:hover {
        color: $brand-color--dark;
      }

      &:visited {
        color: $brand-color--light;
      }
    }
  }

  .media__snippet {
    font-size: $milli-size;
  }
} // END content-block--box



// ------------------------------------------------------------------- //
//  Media Containers - Boxed Alt (Dark Header / Background)
//  
//  Only used in Treaties
// ------------------------------------------------------------------- //

.content-block--box--alt {
  background: $brand-color-3e;

  .content-block__header {
    padding: 10px 20px;
    background: $brand-color-3b;
    border-bottom: none;
  }

  .content-block__title {
    color: #fff;
  }

  .content-block__content {
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: $base-border-color;
  }
}



// ------------------------------------------------------------------- //
//  Media Containers - Sidebar
// ------------------------------------------------------------------- //

[class*="__content--sidebar"]:not(.home__content--sidebar) {
  // Offset to line up with breadcrumbs
  // margin-top: 5px;

  .content-block--box {
    .content-block__header {
      padding: 10px;
    }

    .media__image + .media__content .media__meta {
      padding: 0;
    }
  }
  
  .media__image {
    @include size(50px);

    & + .media__content {
      padding: 10px;
    }
  }

  .media__title {
    font-size: $h2-size;
    font-weight: $bold;
  }

  .media__snippet {
    font-size: $milli-size;
  }
}


// ------------------------------------------------------------------- //
//  Latest News (FRL / Daillies)
// ------------------------------------------------------------------- //

.latest-updates {
  background: $brand-color-3e;

  [class*="content-block--"] {
    padding: 20px;
  }

  // FRL
  .frl & {
    .media__title {
      font-size: 18px;
    }

    .column.first {
      padding-right: 15px;
    }
    .column.last {
      padding-left: 15px;
    }
  }

  // Dailies
  .dailies & {
    .content-block__header {
      border-bottom-color: $brand-color-2;
    }
    .content-block__title {
      color: $brand-color-2;
    }

    .media {
      padding-bottom: 15px;
      border-bottom: 1px solid $base-border-color;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
} // END latest-updates


// ------------------------------------------------------------------- //
//  Listed Content / Landing Pages
// ------------------------------------------------------------------- //

.landing__title {
  display: block;
  margin-bottom: 0;
  padding: 20px;
  background: $brand-color-3b;
  color: #fff;
  font-size: 24px;
  font-weight: $black;
  line-height: 1.2;
  letter-spacing: 1pt;
  text-transform: uppercase;

  @media screen and (max-width: $mobile) {
    margin: 0 -20px;
    padding: 15px 20px;
  }
}


.listing {
  margin-bottom: 60px;
  border: 1px solid $base-border-color;

  .media {
    padding: 0 20px;
  }

  .content-block {
    padding: 0 20px;

    .content-block__header {
      padding-top: 20px;
    }

    .media {
      padding: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  } // END content-block

  input[type="text"] {
    background: #fff;
  }

  @media screen and (max-width: $mobile) {
    margin: 0 -20px 60px;
    border-width: 1px 0;

    .landing__title {
      margin: 0;
    }
  }
} // END listing

// Extra info section, only on Treaties
.listing__description {
  padding: 20px;
  border-width: 0 1px;
  border-style: solid;
  border-color: $base-border-color;

  h2 {
    margin-bottom: .5rem;
    font-weight: $normal;
    line-height: 1;
  }

  p {
    margin-bottom: 0;
  }
} // END listing__description


// Controls (sorting, view options)
.listing__controls {
  @include clearfix;
  padding: 20px;
  background: #f8f8f8;
} // END listing__controls

.listing__controls--view,
.listing__controls--action {
  @include span-columns(6);
  @include hug(both);
}

.listing__controls--view {
  label {
    display: inline-block;
    margin-right: 10px;
    line-height: 30px;
    vertical-align: top;
  }

  form,
  .chosen-container {
    display: inline-block;
    max-width: 225px;
  }

  .form-item {
    margin: 0;
  }
} // END listing__controls--view

.listing__controls--action {
  // CTA Links and Buttons
  a {
    float: right;
  }

  // CTA Links
  a:not([class*="btn--"]) {
    color: $brand-color-2;
    font-size: $milli-size;
    font-weight: $bold;
    line-height: 30px;
    text-transform: uppercase;
  }
}


.listing__pagination {
  @extend %info-bar--nav;
  margin-bottom: 0;
}

.listing__content {
  ol,
  ul {
    margin-bottom: 0;
    padding: 20px;
    list-style: none;

    li {
      margin: 0 0 10px 0;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  } // END ol/ul
} // END listing__content

.load-more {
  display: block;
  margin: 0 25px;
  border-top: 1px solid $base-border-color;

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    padding: 25px 20px;
    background: transparent;
    border: 0;
    color: #999;
    font-family: $brand-font-family;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
}




// -------------------------------------------------------------------- //
//  Authors
// -------------------------------------------------------------------- //

.authors{
  float: left;
  width: 180px;
  margin: 0 20px 15px 0;
  padding: 0 20px 10px 0;
  border-right: 1px solid $base-border-color;
}

.authors__byline{
  margin-bottom: 5px;
  color: $brand-color-3c;
  font-size: $mini-size;
  text-transform: uppercase;
}

.author{
  display: block;
  height: 40px;
  margin-bottom: 5px;
} // END author

.author__image,
.author__name{
  display: table-cell;
  vertical-align: middle;
}

.author__image{
  margin-right: 10px;
  overflow: hidden;

  img{
    width: 40px;
    height: auto;
  }
}

.author__name{
  padding-left: 10px;
  color: $brand-color-3c;
  font-size: $micro-size;
  font-weight: $bold;
  line-height: 1;
  text-transform: uppercase;
}





// ------------------------------------------------------------------- //
//  FATCA
// ------------------------------------------------------------------- //

// .fatca {
//   // Load More button
//   .listing__content .form-submit {
//     @extend .btn--tertiary;
//     display: block;
//     width: 150px;
//     margin: 20px auto;
//   }
// }





// -------------------------------------------------------------------- //
//  Search Term
// -------------------------------------------------------------------- //

.search-highlight {
  padding: 0 2px;
  background: $brand-color-2;
  color: #fff;
  font-weight: $bold;
}




// -------------------------------------------------------------------- //
//  Holiday Message
// -------------------------------------------------------------------- //

.holiday-message {
  .holiday-message__alert {
    padding: 20px;
    background: $brand-color-2;
    color: #fff;
    font-weight: $bold;
    text-transform: uppercase;
  }
}





// -------------------------------------------------------------------- //
//   Feedback Link TEMP
// -------------------------------------------------------------------- //

.feedback-link {
  z-index: 1000;
  position: fixed;
  right: 25px;
  bottom: 0;
  height: 40px;
  padding: 10px;
  background: #666;
  border-radius: 5px 5px 0 0;
  color: #fff;
  transition: height, .3s;

  &:hover,
  &:active {
    height: 60px;
    background: #333;
    color: #fff;
  }

  .page-worldwide-tax-treaties-treaties-compare-results & {
    display: none;
  }

  @media screen and (max-width: $mobile) {
    display: none;
  }
}



// Admin - Tabs Wrapper
#tabs-wrapper {
  margin: -20px auto 20px;
}
