// -------------------------------------------------------------------- //
//  FRL Landing
// -------------------------------------------------------------------- //

.frl-sidebar {
  .content-block__header {
    border-bottom-color: $brand-color-3c;
  }

  .content-block__title {
    color: $brand-color-3c;
  }
}



.frl-content {
  // Datetime comes after titles
  .media__datetime {
    margin-top: 10px;
    margin-bottom: 0;
  }
}



// -------------------------------------------------------------------- //
//  FRL Court Opinions
// -------------------------------------------------------------------- //

[class^="frl-search--"] {
  @include clearfix;
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 15px;
  border-bottom: 1px solid $base-border-color;

  &:last-child {
    border-bottom-width: 0;
  }
}

.frl-search__title {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.frl-search__form {
  float: left;
  width: 75%;
}

.frl-search__or {
  clear: both;
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.frl-search__hyphenate {
  float: left;
  width: 5px;
  margin-right: 5px;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.frl-search__inline {
  float: left;

  .form-item {
    display: inline-block;
  }
}

.frl-search__actions {
  position: absolute;
  right: 0;
  bottom: 15px;
  width: 25%;
  text-align: right;
}



// -------------------------------------------------------------------- //
//  Case Name / Docket Number

.form-item-case-name,
.form-item-docket-name {
  width: 445px;
}



// -------------------------------------------------------------------- //
//  Tax Court Decisions

.frl-search--tcd {

  .form-type-select {
    float: left;
    width: 205px;
    margin-right: 5px;
  }

  // Better alignment
  .form-item-taxc-court-decisions2 {
    width: 215px;
  }

  .form-type-textfield {
    float: left;
    width: 110px;
    margin-right: 5px;
  }

  .form-item-taxc-court-deicions3,
  .form-item-number3 {
    margin-right: 0;
  }
}

// -------------------------------------------------------------------- //
//  Federal Tax Court Decisions

.frl-search--federal-tcd {
  .form-item-fctd1,
  .form-item-fctd3 {
    float: left;
    width: 110px;
    margin-right: 5px;
  }

  .form-item-fctd2 {
    float: left;
    width: 215px;
    margin-right: 5px;
  }

  .form-item-ustc1,
  .form-item-ustc2 {
    float: left;
    width: 120px;
    margin-right: 5px;
  }

  .form-item-ustc3 {
    width: 110px;
    margin-left: 6px;
  }
}






// -------------------------------------------------------------------- //
//  FRL IRS Business Plans
// -------------------------------------------------------------------- //

#frl-irs-business-plans-form {
  .item-list {
    li {
      margin: 0 0 5px 0px;
    }

    .irs_business_plan_subhead {
      margin-left: 15px;
    }
  }
}
