// ------------------------------------------------------------------- //
//  Loading Modal / Messages
// ------------------------------------------------------------------- //

.ajax-progress {
  display: block;
  clear: both;
  padding: 0 0 15px;
  text-align: center;

  .throbber {
    float: none;
    display: inline-block;
  }

  .message {
    display: inline-block;
    padding-left: 0;
  }
}
